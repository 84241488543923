/* .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;   
    padding-top: 8px;
}
.slick-slide img {
    box-shadow: 0 3px 10px rgb(0 0 0 / 45%);
} */
.our-top-recruiters-container {
    margin-top: 3%;
}
.company-container {
    padding: 10px 15px;
}
.slick-slide img {
    width: 100%;
}
.company-container img {
    height: 145px;
    width: 275px;
    object-fit: fill;
}
@media(max-width:575px) {
    .company-container img {
        height: 100px;
        width: 170px;
        object-fit: fill;
    }
}