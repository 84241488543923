.register-for-fdp-mdp-container {
    margin-top: 3%;
    margin-bottom: 5%;
    padding: 0 4% 0 4%;
}

.register-for-fdp-mdp-heading h3 {
    color: #22549e;
    text-align: start;
}

.register-for-fdp-mdp-table-container {
    margin-top: 3%;
}

.register-for-fdp-mdp-table {
    width: 100%;
    text-align: start;
}

.register-for-fdp-mdp-table tr th {
    border: 1px solid #000;
    color: #fff;
    padding: 10px 0px 10px 15px;
    background-color: #22549e;
}

.register-for-fdp-mdp-table tr td {
    border: 1px solid #000;
    padding: 10px 0px 10px 15px;
    color: #22549e;
    font-weight: 500;
}

.register-for-fdp-mdp-workship-container {
    margin-top: 3%;
}

.workship-objective-heading {
    text-align: start;
    border: 1px solid #000;
    color: #fff;
    background-color: #22549e;
    padding: 10px 0px 10px 15px;
}

.workship-objective-list {
    border: 1px solid #000;
    text-align: start;
    padding: 15px 0px 15px 25px;
    color: #22549e;
    font-weight: 700;
}

.register-for-fdp-mdp-cards-container {
    margin-top: 5%;
}

.register-for-fdp-mdp-cards-row {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    margin-bottom: 3%;
}

.register-for-fdp-mdp-cards-column {
    border: 1px solid #000;
    padding-bottom: 5%;
}

.register-for-fdp-mdp-cards-column strong {
    display: block;
    width: 100%;
    background-color: #22549e;
    color: #fff;
    padding: 10px 0px;
    outline: none;
    border: 1px solid #000;
}

.register-for-fdp-mdp-cards-column ul {
    padding-left: 3rem;
    padding-right: 2.5rem;
    padding-top: 1rem;
    text-align: start;
    font-weight: 700;
    color: #22549e;
}

.register-for-fdp-mdp-hr-line {
    color: #22549e;
}

.register-for-fdp-mdp-details-container {
    margin-top: 2%
}

.register-for-fdp-mdp-details-container strong {
    font-weight: bolder;
    color: #22549e;
}

.register-for-fdp-mdp-strong-tags {
    font-size: 18px;
}

.register-for-fdp-mdp-details-container p {
    margin-top: 0.5rem;
    color: #22549e;
}

.register-for-fdp-mdp-remove-margin {
    margin-bottom: 0;
}

.register-for-fdp-mdp-account-details {
    margin: 5% 10% 0% 10%;
}

.account-details-row.row {
    border: 2px solid #000;
    border-radius: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    text-align: start;
    padding-left: 30px;
}

@media(max-width:575px) {
    .register-for-fdp-mdp-heading h3 {
        font-size: 1.2rem;
    }

    .register-for-fdp-mdp-table-container {
        margin-top: 5%;
    }

    .register-for-fdp-mdp-table tr th {
        padding: 5px 0px 5px 7px;
    }

    .register-for-fdp-mdp-table tr td {
        padding: 5px 0px 5px 7px;
    }

    .workship-objective-heading {
        padding: 5px 0px 5px 10px;
    }

    .workship-objective-list {
        padding: 10px 10px 10px 5px;
    }

    .workship-objective-list ul {
        padding-left: 1.5rem;
    }

    .workship-objective-list ul li {
        margin-bottom: 5px;
    }

    .register-for-fdp-mdp-cards-row {
        display: grid;
        grid-template-columns: 100%;
    }

    .register-for-fdp-mdp-cards-column strong {
        padding: 5px 0px;
    }

    .register-for-fdp-mdp-cards-column ul {
        padding-left: 29px;
        padding-right: 10px;
        padding-top: 1rem;
        text-align: start;
        font-weight: 700;
        color: #22549e;
    }
    .register-for-fdp-mdp-cards-column {
        margin-bottom: 5%;
    }
    .register-for-fdp-mdp-account-details {
        margin: 5% 5% 0% 5%;
    }
    .account-details-row.row {
        padding-left: 0px;
        padding-right: 0px;
    }
    .account-number.col-4.register-for-fdp-mdp-account {
        margin-bottom: 2%;
        font-size: 14px;
    }
}