.table-disclosure {
    caption-side: top;
}

.mandatory-disclosur-view-table {
    margin-top: 3%;
    margin-bottom: 5%;
}

.table-column1 tr td {
    border: 1px #000 solid;
    font-size: 20px;
    padding: 10px 10px 10px 15px;
    color: #008080;
    font-weight: 600;
}

.table-column1 tr td:nth-child(2) {
    text-align: start;
    padding-left: 25px;
}

.table-column1 .view-pdf-eye {
    background-color: #008080 !important;
    color: #fff;
    cursor: pointer;
    text-decoration:none;
}
.view-pdf-eye a {
    color: #fff;
    text-decoration:none;
}
@media(max-width:575px) {
    .table1-column-container {
        overflow-x: auto;
    }
    .table-column1 tr td {
        border: 1px #000 solid;
        font-size: 20px;
        padding: 5px 6px 5px 10px;
        color: #008080;
        font-weight: 600;
        font-size: 16px;
        white-space: nowrap;
    }

    .table-column1 tr td:nth-child(2) {
        padding-left: 15px;
        white-space: nowrap;
    }
    .table-column1 tr td:nth-child(3) {
        width: 15%;
        white-space: nowrap;
    }
    .table-column1 tr td {
        padding: 5px 20px;
    }
}