.contact-us-map-container {
    margin: 1% 2% 2% 2%;
    height: 500px;
    object-fit: fill;
}

.contact-us-map-container .gnvs-location-g-map {
    border-radius: 20px;
}

.contact-us-details-container {
    background-color: #225493;
    padding: 30px 50px 30px 50px;
}

.contact-us-details-row {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
}

.contact-us-details-phone-column {
    background-color: #fff;
    padding: 7% 4% 7% 4%;
    display: grid;
    grid-template-columns: 20% 75%;
    justify-content: space-between;
}

.contact-us-details-phone-icon {
    margin: auto auto auto auto;
    font-size: 4.5rem;
    color: #225493;
}

.contact-us-details-phone-number {
    text-align: start;
    padding-right: 5%;
}

.contact-us-details-phone-number strong {
    font-size: 16px;
    color: #225493;
}

.contact-us-details-phone-number p {
    display: inline;
    margin-left: 2%;
    font-weight: bolder;
    font-size: 16px;
    color: #225493;
}

.contact-us-details-email-column {
    background-color: #fff;
    padding: 7% 4% 7% 4%;
    display: grid;
    grid-template-columns: 20% 75%;
    justify-content: space-between;
}

.contact-us-details-email-icons {
    margin: auto;
    font-size: 4.5rem;
    color: #225493;
}

.contact-us-details-email {
    margin: auto;
    color: #225493;
    font-size: 20px;
    font-weight: 600;
    text-align: start;
    width: 100%;
}

.contact-us-details-address-container {
    margin-top: 4%;
    background-color: #fff;
    padding: 8% 8% 8% 0%;
    grid-template-columns: 15% 70%;
    display: grid;
}

.contact-us-details-address-icon {
    margin: auto;
    font-size: 4.5rem;
    color: #22549e;
}

.contact-us-details-address-content {
    width: 100%;
    text-align: center;
}

.contact-us-details-address-content p {
    font-size: 1.3rem;
    color: #225493;
    font-weight: 500;
}

.contact-us-page-form-container {
    margin-top: 3%;
    background-color: #fff;
    padding: 5% 5% 5% 5%;

}

.contact-us-page-form .contact-us-page-form-label {
    display: block;
    color: #225493;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: start;
}

.contact-us-page-form .contact-us-page-form-full-name {
    width: 100%;
    padding: 8px 0px;
}

.contact-us-page-form-row {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
    margin-top: 2%;
}

.contact-us-page-form-label.ask-you-query {
    margin-top: 2%;
}

.ask-your-query-container {
    width: 100%;
}

.contact-us-page-form-submit-btn-container {
    margin-top: 3%;
    text-align: start;
}

.contact-us-page-form-submit-btn-container .contact-us-page-form-submit-btn {
    background-color: #22549e;
    border: none;
    outline: none;
    color: #fff;
    padding: 10px 50px;
}

@media(max-width:575px) {
    .contact-us-map-container {
        height: 430px;
    }

    .contact-us-details-container {
        padding: 30px 20px 30px 20px;
    }

    .contact-us-details-row {
        display: grid;
        grid-template-columns: 100%;
        row-gap: 20px;
    }

    .contact-us-details-phone-column {
        padding: 5% 4% 5% 4%;
        grid-template-columns: 16% 80%;
    }

    .contact-us-details-phone-icon {
        font-size: 2rem;
    }

    .contact-us-details-phone-number {
        padding-right: 0%;
    }

    .contact-us-details-phone-number strong {
        font-size: 14px;
    }

    .contact-us-details-phone-number p {
        font-size: 14px;
    }

    .contact-us-details-phone-number br:nth-child(2) {
        display: none;
    }

    .contact-us-details-email-column {
        padding: 5% 4% 5% 4%;
        grid-template-columns: 16% 80%;
    }

    .contact-us-details-email-icons {
        font-size: 2rem;
    }

    .contact-us-details-email {
        font-size: 16px;
        font-weight: bolder;
    }

    .contact-us-details-email p {
        margin-bottom: 0.5rem;
    }

    .contact-us-details-address-container {
        margin-top: 20px;
        padding: 5% 4% 5% 4%;
        display: grid;
        grid-template-columns: 16% 80%;
        justify-content: space-between;
    }

    .contact-us-details-address-icon {
        font-size: 2rem;
    }

    .contact-us-details-address-content {
        text-align: start;
    }

    .contact-us-details-address-content p {
        font-size: 14px;
        word-spacing: 2px;
        font-weight: bolder;
        margin: 0;
    }

    .contact-us-page-form-container {
        margin-top: 20px;
        padding: 5% 4% 5% 4%;
    }

    .contact-us-page-form .contact-us-page-form-label {
        font-size: 1.1rem;
        margin-bottom: 0.3rem;
    }

    .contact-us-page-form .contact-us-page-form-full-name {
        padding: 5px 3px;
        
    }

    .contact-us-page-form-row {
        margin-top: 3.5%;
        grid-template-columns: 100%;
        row-gap: 6.5%;
    }

    .contact-us-page-form-label.ask-you-query {
        margin-top: 5.5%;
    }

    .ask-your-query-container {
        padding: 5px 3px;
        overflow: auto;
    }

    .contact-us-page-form-submit-btn-container .contact-us-page-form-submit-btn {
        background-color: #22549e;
        border: none;
        outline: none;
        color: #fff;
        padding: 5px 20px;
        font-size: 14px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
}