.instituion-achievements-container .instituion-achievements {
    display: grid;
    grid-template-columns: 48% 48%;
    margin-top: 3%;
    margin-bottom: 5%;
    justify-content: space-between;
}
.instituion-achievements-columns {
    padding-bottom: 3%;
    text-align: start;
    border-bottom: 1px solid;
    margin-top: 5%;
}
.instituion-achievements-columns ul li {
    font-weight: 600;
}
.instituion-achievements-columns .instituion-achievements-view-more-btn {
    padding-left: 2rem;
}
.instituion-achievements-view-more-btn a {
    text-decoration: none;
}
@media(max-width:575px) {
    .instituion-achievements-container .instituion-achievements {
        margin-top: 5%;
        display: grid;
        grid-template-columns: 100%;
    }
}