.mandatory-committees-container {
    margin-top: 3%;
    margin-bottom: 5%;
}

.mandatory-committees-row {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
}

.mandatory-committees-columns {
    text-align: start;
    margin-bottom: 4%;
}

.mandatory-committees-title {
    display: inline-block;
    border: 1px solid #000;
    padding: 15px;
    text-align: start;
    width: 75%;
    font-size: 20px;
    font-weight: 600;
    color: #22549e;
}

.mandatory-committees-pdf {
    display: inline-block;
    border: 1px solid #000;
    padding: 20px 44px 16px 40px;
    background-color: #22549e;
    cursor: pointer;
}

.mandatory-committees-pdf-view {
    font-size: 19px;
    color: #fff;
}

@media(max-width:575px) {
    .mandatory-committees-row {
        display: grid !important;
        grid-template-columns: 100% !important;
    }

    .mandatory-committees-title {
        display: inline-block !important;
        border: 1px solid #000 !important;
        padding: 5px 5px 5px 10px !important;
        text-align: start !important;
        width: 67% !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #22549e !important;
    }

    .mandatory-committees-pdf {
        display: inline-block !important;
        border: 1px solid #000 !important;
        padding: 5px 10px 2.5px 10px !important;
        background-color: #22549e !important;
        cursor: pointer !important;
        text-align: center !important;
        width: 20% !important;
    }
    .mandatory-committees-columns {
        text-align: center;
    }
}