.organizational-structure-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.organizational-strcuture-image-container img {
    height: 100%;
    width: 100%;
    border: 8px double #008080;
}
@media(max-width:575px) {
    .organizational-structure-container {
        margin-top: 5%;
    }
}