.vision-mission-container {
    margin-top: 5%;
    margin-bottom: 3%;
}


.vision-mission {
    display: grid;
    grid-template-columns: 50% 50%;
}

.missions-container ul {
    padding: 0px 50px;
}

.missions-container ul li {
    padding-left: 5px;
    text-align: start;
}

.vision-container img {
    width: 17rem;
    height: 15rem;
    padding: 3% 5% 2% 5%;
    object-fit: fill;
}

.missions-container img {
    width: 17rem;
    height: 15rem;
    padding: 3% 5% 2% 5%;
    object-fit: fill;
}

@media(max-width:575px) {
    .vision-mission {
        display: grid;
        grid-template-columns: 100%;
        padding: 0px 25px;
    }

    .missions-container ul {
        padding: 0px 0px 0px 20px;
    }

    .missions-container ul li {
        padding-left: 0px;
    }

    .vision-container img {
        width: 14rem;
        height: 12rem;
        padding: 3% 5% 2% 5%;
        object-fit: fill;
    }

    .missions-container img {
        width: 14rem;
        height: 12rem;
        padding: 3% 5% 2% 5%;
        object-fit: fill;

    }
}