.top-header-container {
    background-color: #008080;
    padding: 10px 15px;
}
.top-header-rows::after {
    content: "";
    display: table;
    clear: both;
}
.top-headers-columns {
    float: left;
    width: 50%;
}
.top-header-container .tabs {
    display: inline-block;
    float: left;
    text-align: start;
}
.tabs button {
    background:none;
    border: none;
    outline: none;
    border-right: 1px #fff solid;
    padding:5px 5px;
    color: #fff;
    margin-left: 2%;
    font-size: 16px;
    padding-right: 9px;
}
.tabs button:hover {
    background:none;
    border: none;
    outline: none;
    border-right: none;
    padding:5px 9px;
    color: #000;
    background-color: #FFEE00;
}

.top-header-container .footer-icons.social-media-icons {
    display: inline-block;
    margin-top: 0px;
    padding-bottom: 0px;
    text-align: end;
}
.social-media-links-top {
    margin-right: 1%;
}
.social-media-links-top .social-container .social-svg .social-svg-mask {
    fill: #fff !important;
}
.social-media-links-top:hover .social-container .social-svg .social-svg-mask {
    fill: #FFEE00 !important;
}
.tabs.top-headers-columns .admission-btn {
    background: none;
    outline: none;
    border-right: none;
    padding: 5px 9px;
    color: #000;
    background-color: #FFEE00;
    margin-left: 2%;
    padding-right: 9px;
    font-weight: bold;
    border: 4px solid #ff0e0e; /* Initial border color */
    animation: borderAnimation 1s infinite; /* Apply animation */
}
@keyframes borderAnimation {
    0% {
      border-color: #ff0e0e;
    }
    50% {
      border-color: #22549e;
    }
    100% {
      border-color: #ff0e0e;
    }
  }
.tabs.top-headers-columns .placement-btn {
    background: none;
    border: none;
    outline: none;
    border-right: none;
    padding: 5px 9px;
    color: #000;
    background-color: #FFEE00;
    margin-left: 2%;
    padding-right: 9px;
}
.tabs.top-headers-columns .payment-btn {
    background: none;
    border: none;
    outline: none;
    border-right: none;
    padding: 5px 9px;
    color: #ffff;
    background-color: #e65146;
    margin-left: 2%;
    padding-right: 9px;
}
@media(max-width:575px) {
    .top-headers-columns {
        width: 100%;
    }
    .tabs button {
        font-size: 14px;
        border-right: none;
    }
    .top-header-container .footer-icons.social-media-icons {
        margin-top: 5%;
        text-align: center;
    }
    .social-media-links-top {
        margin-right: 3%;
    }
    .social-media-links-top {
        height: 35px !important;
        width: 35px !important;
    }
    .top-header-container .tabs {
        float: none;
        text-align: center;
    }
}