.research-cell-container {
	margin-top: 3%;
	margin-bottom: 5%;
	padding: 0px 20px;
}
table.research-cell-table {
	width: 100%;
}
table.research-cell-table tr th {
	border: 1px #000 solid;
	background-color: #22549e;
	color: #fff;
	font-size: 14px;
	padding: 10px 15px 10px 15px;
	text-align: start;
}
.research-cell-table tr td {
	border: 1px #000 solid;
	padding: 10px 10px 10px 10px;
	text-align: start;
	font-size: 14px;
	font-weight: 600;
}
.research-cell-table tr td:nth-child(6) {
	text-align: center;
}
.research-cell-table tr td:nth-child(7) {
	text-align: center;
}
.research-cell-table tr td:nth-child(8) {
	text-align: center;
}
.researcher-details::after {
	display: table;
	content: "";
	clear: both;
}
.researcher-details .researcher-name {
	float: left;
	width: 60%;
	text-align: start;
}
.researcher-details .researcher-file {
	width: 35px;
	text-align: center;
	background-color: #22549e;
	height: 35px;
	padding-top: 5px;
	float: right;
	cursor: pointer;
}

.researcher-file .research-file-pdf-eye {
	color: #fff;
	font-size: 16px;
}
.research-cell-table tr td.research-cell-pdf {
	background-color: #22549e;
	cursor: pointer;
}
.research-cell-table tr td.research-cell-pdf .research-cell-pdf-view {
	font-size: 18px;
	color: #fff;
}
.pictorial-heading {
	margin-top: 2%;
	margin-bottom: 2%;
}
.pictorial-heading h3 {
	color: #22549e;
}
.pictorial-image-container img {
	height: 100%;
	width: 100%;
	border: 8px double #22549e;
}
.linkcta {
	color: #22549e;
}
@media (max-width: 575px) {
	.research-cell-table-container {
		overflow-y: auto;
	}
	table.research-cell-table tr th {
		padding: 10px 20px;
		white-space: nowrap;
	}
	.research-cell-table tr td {
		padding: 10px 20px;
		white-space: nowrap;
	}
	.pictorial-heading {
		margin-top: 5%;
	}
}
