.research-paper-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.research-paper-content p {
    text-align: start;
}
.research-paper-heading {
    text-align: start;
    color: #22549e;
}
.research-paper-published-heading{
    margin-top: 4%;
    text-align: start;
    color: #22549e;
}
.research-paper-table-container{
    margin-top: 4%;
}
table.research-paper-table {
    width: 100%;
}
.research-paper-table tr th {
    border: 1px #000 solid;
    background-color: #22549e;
    color: #fff;
    padding: 10px 15px;
}
.research-paper-table tr th:nth-child(1) {
    padding: 10px 20px;
}
.research-paper-table tr td {
    border: 1px #000 solid;
    color: #22549e;
    font-weight: 600;
    padding: 10px 15px;
}
.research-paper-table tr td a {
    text-decoration: none;
    color: #22549e;
}
@media(max-width:575px) {
    .research-paper-published-heading {
        margin-bottom: 5%;
    }
    .research-paper-published-heading h4 {
        margin: 0;
    }
    .research-paper-table-container {
        width: 100%;
        overflow-y: auto;
    }
    .research-paper-table tr th:nth-child(1) {
        padding: 5px 10px !important;
    }
    .research-paper-table tr th {
        padding: 5px 10px !important;
    }
    .research-paper-table tr td {
        padding: 5px 10px !important;
        white-space: nowrap;
    }
}