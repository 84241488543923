.modal-body.row .col-1 {
    font-weight: 700;   
}
.modal-header {
    background-color: #22549e;
    color: #fff;
}
.modal-header .modal-title {
    margin-left: auto;
    margin-right: auto;
}
.modal-header .btn-close {
    margin: 0;
    color: #fff;
}
.close-popup-btn {
    font-size: 1.4rem;
    cursor: pointer;
}
.close-popup-btn path {
    stroke: #fff;
    stroke-width: 4;
}