.faculty-login-container {
	padding-bottom: 5%;
}
.faculty-login-btn-continer {
	margin-top: 5%;
	width: 100%;
	display: block;
}
.faculty-login-btn {
	width: 100%;
}
