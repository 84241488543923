.student-council-content {
	/* margin-top: 3%;
    margin-bottom: 5%; */
}
.student-council-container {
	margin-top: 3%;
	margin-bottom: 5%;
	display: flex;
	flex-direction: column;
}
.student-council-heading {
	display: inline-block;
	border: 1px solid #000;
	padding: 15px;
	width: 45%;
	text-align: start;
	font-weight: 600;
	color: #22549e;
}

.student-councill-pdf {
	padding: 9px 10px 13px 10px;
	background-color: #22549e;
	color: #fff;
	font-size: 20px;
	width: 9%;
}

@media (max-width: 575px) {
	.student-council-content {
		margin-top: 5%;
	}

	.student-council-heading {
		width: 70%;
		padding: 5px 10px;
	}

	.student-councill-pdf {
		padding: 5px 10px 5px 10px;
		background-color: #22549e;
		color: #fff;
		font-size: 16px;
		width: 15%;
		outline: none;
		border: 1px solid #000;
	}
	.student-councill-pdf .student-councill-pdf-view {
		margin-bottom: 3px;
	}
}
