.latest-news-events {
    margin-top: 4%;
    position: relative;
}

.background-image img {
    width: 100%;
    height: 80vh;
    object-fit: fill;
}

.latest-news {
    padding-top: 5%;
    width: 35%;
    text-align: start;
    position: absolute;
    top: -5%;
    right: 5%;
    background-color: #FFEE00;
}

.latest-news h4 {
    color: #22549E;
    margin-left: 8%;
    padding-bottom: 15px;
}

.marquee-container {
    padding-right: 8%;
}

.marquee-content {
    display: inline-block;
    width: 100%;
    margin-bottom: 1%;
    margin-left: 8%;
    color:#22549E;
}

.marquee-content p {
    margin-left: 1%;
    display: inline-block;
    margin-left: 15px;
}

.marquee-content .event-date {
    display: inline-block;
}

.event-month {
    font-size: 14px;
    text-decoration: underline;
    font-weight: 500;
}

.latest-news-marquee-container {
    width: 100%;
    height: 100%;
    max-height: 200px;
}

@media(max-width:575px) {
    .latest-news {
        padding-top: 4%;
        width: 100%;
        text-align: start;
        position: absolute;
        top: 0%;
        right: 0%;
        background-color: #FFEE00;
        opacity: 0.8;
    }

    .latest-news-marquee-container {
        width: 100%;
        height: 21.8vh;
    }

    .background-image img {
        width: 100%;
        opacity: 0.7;
    }
    .home-page-latest-news-img img {
        height: 30vh;
    }

    .marquee-content {
        display: grid;
        width: 100%;
        margin-bottom: 1%;
        margin-left: 8%;
        grid-template-columns: 20% 80%;
    }
    .marquee-content p {
        font-size: 15px;
    }
    .marquee-content .event-date {
        font-size: 1rem;
    }
}