.pages-heading-container {
    
    width: 100%;
    display: block;
    background-color: #008080;
    color: #fff;
    text-align: center;
    padding:30px 0px;
    
}
@media(max-width:575px){
    .pages-heading-container{
        
        padding:15px 0px;
    }
}
/* .pages-heading h2{
    margin-top: 2%;
} */