.terms-and-conditions-container {
    margin-top: 3%;
    margin-bottom: 3%;
}
.terms-and-conditions-content{
    text-align: start;
}
.terms-and-conditions-heading {
    background-color: #22549e;
    padding: 12px;
    text-align: center;
    margin-bottom: 2%;
}
.terms-and-conditions-heading .terms-and-conditions-title {
    margin: 0;
    color: #fff;
    font-weight: 600;
}
.terms-and-conditions-alphabet-list {
    list-style-type: lower-alpha;
}