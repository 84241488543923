.photo-gallery-page-container {
    margin-top: 3%;
    margin-bottom: 5%;
}

.photo-gallery-page-single-card {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 18px 20px;
    margin-bottom: 4%;
}

.photo-gallery-page-single-card h5 {
    color: #22549e;
    text-align: center;
}

.photo-gallery-page-images-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: space-between;
    margin-top: 1.5%;

}
.event-description {
	font-size: 14px;
	color: #000000;
    text-align: left;
}

.event-date {
	font-size: 14px;
	color: #000000;
    text-align: left !important;
    font-weight: 700;
    border-bottom: 1px solid #969696;
}
.event-title {
	font-size: 18px;
	color: #000000;
    text-align: left;
    font-weight: 700;
}

.photo-gallery-page-images-container img {
    height: 220px;
    width: 350px;
    border-radius: 20px;
    margin-left: 12%;
    object-fit: fill;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo-gallery-page-more-image {
    text-align: end;
    margin-top: 2%;
    margin-right: 1.5%;
}

.photo-gallery-page-more-image-button {
    background-color: #225493;
    color: #fff;
    border-radius: 10px;
    padding: 7px 16px;
    font-size: 14px;
    border: none;
    outline: none;
}
.photo-gallery-page-more-image-button:hover {
    background-color: #008080;
}
@media(max-width:575px) {
    .photo-gallery-page-container {
        margin-top: 5%;
    }

    .photo-gallery-page-single-card {
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        padding: 10px 5px;
        margin-bottom: 8%;
    }

    .photo-gallery-page-images-container {
        grid-template-columns: 100%;
        gap: 20px;
    }

    .photo-gallery-page-images-container img {
        height: 220px;
        width: 310px;
        border-radius: 20px;
        margin-left: auto;
        object-fit: fill;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
    }
    .photo-gallery-page-more-image {
        margin-top: 3%;
    }
    .photo-gallery-page-more-image-button {
        padding: 5px 10px;
    }
}