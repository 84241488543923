.chairman-message-container {
    padding: 4% 5%;
}
.chairman-details {
    display: flex;
    justify-content: center;
}
.chairman-image img {
    border-radius: 100%;
    max-width: 300px;
    max-height: 300px;
    min-width: 250px;
    min-height: 250px;
}
.chairman-image h2 {
    padding: 4%;
    color: #22549E;
}
.chairman-message-content {
    text-align: start;
    padding: 1%;
}
.chairman-message-content strong {
    font-size: 22px;
    font-weight: 400;
}
.chairman-message-content .with-best {
    margin-bottom: 0;
}
@media(max-width:575px) {
    .chairman-image h2 {
        font-size: 1.3rem;
        margin: 0;
    }
    .chairman-message-content strong {
        font-size: 18px;
    }
    .chairman-message-content p {
        font-size: 15px;
    }
}