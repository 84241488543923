.admission-enquiry-form-container {
	background-color: rgb(0, 128, 128, 0.2);
}

.admission-enquiry-form {
	padding-top: 5%;
}

.admission-enquiry-form-start {
	background-color: #fff;
	padding: 3% 2% 3% 2%;
	border-radius: 16px;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.admission-enquiry-form-start .admission-enquiry-form-headings {
	text-align: start;
	display: block;
	color: #22549e;
	font-size: 1.2rem;
	font-weight: 600;
}

.admission-enquiry-form-start .admission-enquiry-form-input {
	display: block;
	height: 40px;
	margin-bottom: 1%;
	width: 100%;
	border-radius: 8px;
	padding-left: 12px;
	border: 0.1px solid #c2adad;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.admission-enquiry-gender-age {
	display: grid;
	grid-template-columns: 49% 49%;
	justify-content: space-between;
}

.admission-enquiry-form-btn-container {
	margin-top: 2%;
	width: 50%;
	display: grid;
	grid-template-columns: 20% 20%;
	column-gap: 25px;
}

.admission-enquiry-form-btn-container .admission-enquiry-form-submit-btn {
	border: none;
	outline: none;
	background-color: #22549e;
	color: #fff;
	padding: 6px 0px;
	border-radius: 5px;
}

.admission-enquiry-form-btn-container .admission-enquiry-form-submit-btn:hover {
	background-color: #008080;
}

.admission-enquiry-form-btn-container .admission-enquiry-form-reset-btn {
	border: none;
	outline: none;
	background-color: #22549e;
	color: #fff;
	padding: 6px 0px;
	border-radius: 5px;
}
.admission-enquiry-form-btn-container .admission-enquiry-form-reset-btn:hover {
	background-color: #008080;
}
.admission-enquiry-form-points-container {
	margin-top: 2%;
	text-align: start;
	padding-bottom: 2%;
}

.admission-enquiry-form-points-container ul {
	margin: 0;
}

.admission-enquiry-form-points-container ul li {
	color: #008080;
	font-weight: 700;
}

@media (max-width: 575px) {
	.admission-enquiry-form {
		padding: 5% 3%;
	}

	.admission-enquiry-form-start {
		padding: 3%;
	}

	.admission-enquiry-gender-age {
		margin-top: 3%;
	}

	.admission-enquiry-college-name {
		margin-top: 3%;
	}

	.admission-enquiry-email {
		margin-top: 3%;
	}

	.admission-enquiry-number {
		margin-top: 3%;
	}

	.admission-enquiry-further-study {
		margin-top: 3%;
	}

	.admission-enquiry-form-btn-container {
		margin-top: 5%;
		width: 50%;
		display: grid;
		grid-template-columns: 40% 40%;
		column-gap: 25px;
	}
}
.admissionLink {
	text-decoration: none;
}

.admission-enquiry-form-points-container {
	margin: 20px auto;
	max-width: 1000px;
  }

  .admission-enquiry-form-points-table {
	width: 100%;
	border-collapse: collapse;
	text-align: start;
  }
  .admission-enquiry-form-points-table tr th {
	border: 1px solid #000;
	padding: 10px 0px 10px 10px;
	background-color: #22549e;
	color: #fff;
  }
  .admission-enquiry-form-points-table tr td{
	border: 1px solid #000;
	padding: 10px 0px 10px 10px;
	color: #22549e;
	font-weight: 600;
  }
  .link-cta {
	color: #fff;
	text-decoration:none;
}
.link-cta:hover {
	color: #fff;
	text-decoration:none;
}
.admission-page-pdf {
	text-align: center;
	padding: 10px 10px 10px 10px !important;
	color: #fff !important;
	background-color: #22549e;
}
/* @media (max-width: 575px) {
	.admission-enquiry-form-points-table {
		overflow-x: auto;
	}
	.admission-enquiry-form-points-table tr th {
		padding: 5px 8px 5px 8px;
		white-space: nowrap;
	}
	.admission-enquiry-form-points-table tr td {
		white-space: nowrap;
	}
	.admission-enquiry-form-points-table tr td {
		text-align: center;
		padding: 5px 8px 5px 8px;
		white-space: nowrap;
	}
	.admission-page-pdf {
		padding: 10px 25px !important;
	}
} */
