.publications-container {
    margin-top: 3%;
    margin-bottom: 5%;
}

.publications-content p {
    text-align: start;
}

.all-publications-heading {
    margin-top: 3%;
    text-align: start;
}

.all-publications-heading h4 {
    color: #22549E;
}

.publication-count-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: start;
    justify-content: space-between;
    text-align: start;
    margin-top: 4%;
    column-gap: 5%;
    padding-bottom: 0%;
}

.publications-international-count {
    display: inline-block;
    padding: 20px 25px;
    background: #22549e;
    color: #fff;
    box-shadow: 6px -7px 0px rgb(255 238 0);
}

.publications-international-count h5 {
    margin-bottom: 0;
}

.publications-international-heading {
    display: inline-block;
    margin-left: 6px;
    background: #22549e;
    color: #fff;
    padding: 10px 35px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
}

.publications-international-heading h5 {
    margin-bottom: 0;
}

.publications-national-count {
    display: inline-block;
    padding: 20px 25px;
    background: #22549e;
    color: #fff;
    box-shadow: 6px -7px 0px rgb(255 238 0);
}

.publications-national-count h5 {
    margin-bottom: 0;
}

.publications-national-heading {
    display: inline-block;
    margin-left: 6px;
    background: #22549e;
    color: #fff;
    padding: 10px 35px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
}

.publications-national-heading h5 {
    margin-bottom: 0;
}

.publications-other-count {
    display: inline-block;
    padding: 20px 25px;
    background: #22549e;
    color: #fff;
    box-shadow: 6px -7px 0px rgb(255 238 0);
}

.publications-other-count h5 {
    margin-bottom: 0;
}

.publications-other-heading {
    display: inline-block;
    margin-left: 6px;
    background: #22549e;
    color: #fff;
    padding: 10px 35px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
}

.publications-heading-heading h5 {
    margin-bottom: 0;
}

table caption {
    caption-side: top;
    color: #fff;
    text-align: center;
    background-color: #22549e;
    font-weight: 600;
}

table.publications-table {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
}

table.publications-table tr th {
    border: 1px #000 solid;
    background-color: #22549e;
    color: #fff;
    width: 1%;
    padding: 10px 0px;
}

.publications-table tr td {
    color: #22549E;
    font-weight: 600;
    border: 1px #000 solid;
    padding: 10px 0px;
}

.publications-table tr td a {
    text-decoration: none;
    color: #22549E;
}

@media(max-width:575px) {
    .all-publications-heading {
        margin-top: 5%;
        margin-bottom: 2%;
    }

    .all-publications-heading h4 {
        margin: 0;
    }

    .publication-count-container {
        grid-template-columns: 100%;
        align-items: start;
        justify-content: space-between;
        text-align: start;
        margin-top: 4%;
        row-gap: 10%;
        margin-bottom: 15%;
        margin-left: 10%;
    }
    .publications-international-count {
        padding: 15px 20px;
    }
    .publications-national-count {
        padding: 15px 20px;
    }
    .publications-other-count {
        padding: 15px 26px;
    }
    .publications-international-heading {
        padding: 5px 25px;
    }
    .publications-table-container {
        overflow-y: auto;
        width: 100%;
    }
    table.publications-table tr th {
        white-space: nowrap;
    }
    .publications-table tr td {
        white-space: nowrap;
    }
}