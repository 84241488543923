.dynamic-exam-time-table-container {
    margin-top: 3%;
    margin-bottom: 3%;
}
.dynamic-course-heading {
    color: #22549E;
    margin-bottom: 3%;
}
table.dynamic-exam-time-table {
    margin-left: auto;
    margin-right: auto;
}
.dynamic-exam-time-table tr th {
    background-color: #22549E;
    color:#fff;
    border: 1px #000 solid;
    padding: 10px 25px;
}
.dynamic-exam-time-table tr td {
    border: 1px #000 solid;
    font-weight: 600;
    padding: 10px 20px;
}
.dynamic-exam-time-table tr td.dyanmic-exam-time-table-pdf {
    background-color: #22549E;
}
.dyanmic-exam-time-table-pdf .dyanmic-exam-time-table-pdf-view {
    color: #fff;
    font-size: 18px;
}
@media(max-width:575px) {
    .dyanamic-exam-time-table {
        overflow-y: auto;
    }
    .dynamic-exam-time-table tr th {
        padding: 5px 15px;
        white-space: nowrap;
    }
    .dynamic-exam-time-table tr td {
        padding: 5px 15px;
        white-space: nowrap;
    }
}