.about-us-container {
    padding: 3% 5%;
}
.about-us-message-container p {
    text-align: start;
    font-size: 16px;
}
.unique-accolades-container {
    margin-top: 3%;
    padding: 2% 5%;
    
}
.unique-features-contaniner {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
}
.unique-features-heading {
    background-color: #008080;
    padding: 22%;
    box-shadow: 0 5px 20px rgb(0 0 0 / 0.3);
    color: #fff;
}
.unique-features-content {
    padding: 14% 0% 12% 5%;
    box-shadow: 0 5px 20px rgb(0 0 0 / 0.3);
    
}
.unique-features-content ul li {
    text-align: start;
    padding-left: 2%;
}
.accolades-container {
    margin-top: 6%;
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
}
.accolades-heading {
    background-color: #008080;
    padding: 18%;
    box-shadow: 0 5px 20px rgb(0 0 0 / 0.3);
    color: #fff;
}
.accolades-content {
    padding: 10% 4% 10% 3%;
    box-shadow: 0 5px 20px rgb(0 0 0 / 0.3);
}
.accolades-content ul li {
    text-align: start;
    padding-left: 2%;
}
@media(max-width:575px){
    .unique-features-heading h2{
        font-size: 1.4rem;
    }
    .about-us-message-container p {
        font-size: 16px;
    }
    .unique-accolades-container {
        padding: 3% 0%;
    }
    .unique-features-contaniner {
        grid-template-columns: 100%;
    }
    .unique-features-heading {
        padding: 16%;
    }
    .unique-features-content {
        padding: 8% 0% 1% 1%;
    }
    .accolades-container {
        margin-top: 10%;
        grid-template-columns: 100%;
    }
    .accolades-heading h2 {
        font-size: 1.4rem;
    }
}