.mandatory-committees-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.mandatory-committees-row {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
}
.mandatory-committees-columns{
    text-align: start;
    margin-bottom: 4%;
}
.mandatory-committees-title {
    display: inline-block;
    border: 1px solid #000;
    padding: 15px;
    text-align: start;
    width: 75%;
    font-size: 20px;
    font-weight: 600;
    color: #22549e;
}
.mandatory-committees-pdf {
    display: inline-block;
    border: 1px solid #000;
    padding: 20px 44px 16px 40px;
    background-color: #22549e;
    cursor: pointer;
}
.mandatory-committees-pdf-view {
    font-size: 19px;
    color: #fff;
}
@media(max-width:575px) {
    .mandatory-committees-columns {
        text-align: center;
    }
}