.alumni-speak-container {
    background-color: rgba(217, 217, 217, 0.3);
    padding-top: 4.5%;
    padding-bottom: 4.5%;
    margin-top: 3%;
}
.alumni-slider-container {
    padding: 1.3% 8% 0% 8%;
}
.alumni-img {
    display: flex;
    justify-content: center;
}
.alumni-img img {
    width:8rem !important;
    border-radius: 100%;
}
.alumni-message p {
    padding: 0px 20px;
}
.slider-previous-btn {
    height: 3rem;
    width: 3rem;
}
.slick-prev {
    left: -45px;
}
.slick-next-btn {
    width: 3rem;
    height: 3rem;
}
.slick-next {
    right: -45px;
}
.linked-icon-alumni {
    font-size: 25px;
}

@media(max-width:575px){
    .alumni-speak-container {
        padding-top: 5%;
    }
    .alumni-message p {
        padding: 0;
        font-size: 13px;
        text-align: start;
        margin-top: 10px;
        padding: 0px 10px;
        text-align: justify;
    }
    .slider-previous-btn {
        height: 2rem;
        width: 2rem;
    }
    .slick-prev {
        left: -30px;
    }
    .slick-next-btn {
        width: 2rem;
        height: 2rem;
    }
    .slick-next {
        right: -30px;
    }
    .alumni-details h4{
        font-size: 1rem;
    }
    .alumni-details p {
        font-size: 1rem;
    }

}