.carousel-control-next,
.carousel-control-prev {
	opacity: 1;
}

.slider-left-btn {
	font-size: 2rem;
	color: #000;
	opacity: 1;
}

.slider-right-btn {
	font-size: 2rem;
	color: #000;
	opacity: 1;
}

.carousel-indicators [data-bs-target] {
	width: 10px;
	height: 10px;
	border-radius: 100%;
}

.carousel-item img {
	height: 85vh;
	width: 100% !important;
	object-fit: fill;
}

@media (min-width: 575px) {
	#carouselExampleIndicators {
		/* min-height: 670px; */
	}
}
@media (max-width: 575px) {
	.carousel-item img {
		height: 30vh;
	}

	.background-image img {
		width: 100%;
		height: 30vh;
		object-fit: fill;
	}
}
