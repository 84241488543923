.nirf-page-container {
    margin-top: 3%;
    margin-bottom: 3%;
}

.nirf-page-table {
    width: 100%;
}

.nirf-page-table tr td {
    border: 1px solid #000;
    color: #22549e;
    font-weight: 700;
    padding: 18px;
    font-size: 18px;
}

.nirf-page-table tr td:nth-child(1) {
    width: 5%;
}

.nirf-page-table tr td:nth-child(2) {
    text-align: start;
}

.nirf-page-pdf {
    background-color: #22549e;
    color: #fff !important;
    width: 10%;
}
.nirf-page-pdf a {
    color: #fff !important;
}
.nirf-page-pdf .nirf-page-pdf-view {
    font-size: 20px;
}

@media(max-width:575px) {
    .nirf-page-table-container {
        overflow-x: auto;
    }

    .nirf-page-table tr td {
        border: 1px solid #000;
        color: #22549e;
        font-weight: 700;
        padding: 5px;
        font-size: 16px;
        white-space: nowrap;
    }

    .nirf-page-table tr td:nth-child(1) {
        width: 10%;
    }

    .nirf-page-pdf {
        width: 16%;
        font-size: 18px;
    }
}