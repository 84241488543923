.placement-table-overview-container {
    margin-top: 3%;
    margin-bottom: 3%;
}

.placement-table-overview {
    width: 100%;
}

.placement-table-overview tr td {
    border: 1px solid #000;
    padding: 10px 5px;
    font-weight: 600;
    color: #22549e
}

.placement-table-overview tr td:nth-child(1) {
    width: 7%;
}

.placement-table-overview tr td:nth-child(2) {
    text-align: start;
    padding-left: 25px;
}

.placement-table-overview tr td:nth-child(3) {
    width: 8.5%;
}

.placement-table-overview-pdf {
    background-color: #22549e;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.placement-table-overview-pdf .placement-table-overview-pdf-view {
    color: #fff;
}


.placement-table-corporate-interface-container {
    margin-bottom: 3%;
}

.placement-table-corporate-interface {
    width: 100%;
}

.placement-table-corporate-interface tr td {
    border: 1px solid #000;
    padding: 10px 5px;
    font-weight: 600;
    color: #22549e
}

.placement-table-corporate-interface tr td:nth-child(1) {
    width: 7%;
}

.placement-table-corporate-interface tr td:nth-child(2) {
    text-align: start;
    padding-left: 25px;
}

.placement-table-corporate-interface tr td:nth-child(3) {
    width: 8.5%;
}

.placement-table-corporate-interface-pdf {
    background-color: #22549e;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.placement-table-corporate-interface-pdf .placement-table-corporate-interface-pdf-view {
    color: #fff;
}



.placement-table-learning-dev-container {
    margin-bottom: 3%;
}

.placement-table-learning-dev {
    width: 100%;
}

.placement-table-learning-dev tr td {
    border: 1px solid #000;
    padding: 10px 5px;
    font-weight: 600;
    color: #22549e
}

.placement-table-learning-dev tr td:nth-child(1) {
    width: 7%;
}

.placement-table-learning-dev tr td:nth-child(2) {
    text-align: start;
    padding-left: 25px;
}

.placement-table-learning-dev tr td:nth-child(3) {
    width: 8.5%;
}

.placement-table-learning-dev-pdf {
    background-color: #22549e;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.placement-table-learning-dev-pdf .placement-table-learning-dev-pdf-view {
    color: #fff;
}



.placement-table-alumini-container {
    margin-bottom: 3%;
}

.placement-table-alumini {
    width: 100%;
}

.placement-table-alumini tr td {
    border: 1px solid #000;
    padding: 10px 5px;
    font-weight: 600;
    color: #22549e
}

.placement-table-alumini tr td:nth-child(1) {
    width: 7%;
}

.placement-table-alumini tr td:nth-child(2) {
    text-align: start;
    padding-left: 25px;
}

.placement-table-alumini tr td:nth-child(3) {
    width: 8.5%;
}

.placement-table-alumini-pdf {
    background-color: #22549e;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.placement-table-alumini-pdf .placement-table-alumini-pdf-view {
    color: #fff;
}

@media(max-width:575px) {
    .placement-table-overview-container {
        overflow-x: auto;
    }

    .placement-table-overview tr td {
        padding: 5px 10px;
        white-space: nowrap;
    }

    .placement-table-overview tr td:nth-child(2) {
        padding-left: 10px;
    }

    .placement-table-overview tr td:nth-child(3) {
        padding: 5px 25px;
    }

    .placement-table-corporate-interface-container {
        overflow-x: auto;
    }

    .placement-table-corporate-interface tr td {
        padding: 5px 10px;
        white-space: nowrap;
    }

    .placement-table-corporate-interface tr td:nth-child(2) {
        padding-left: 10px;
    }

    .placement-table-corporate-interface tr td:nth-child(3) {
        padding: 5px 25px;
    }

    .placement-table-learning-dev-container {
        overflow-x: auto;
    }

    .placement-table-learning-dev tr td {
        white-space: nowrap;
        padding: 5px 10px;
    }

    .placement-table-learning-dev tr td:nth-child(2) {
        padding-left: 10px;
    }

    .placement-table-learning-dev tr td:nth-child(3) {
        padding: 5px 25px;
    }

    .placement-table-alumini-container {
        overflow-x: auto;
    }
    .placement-table-alumini tr td {
        padding: 5PX 10PX;
    }
    .placement-table-alumini tr td:nth-child(2) {
        padding-left: 10px;
    }
    .placement-table-alumini tr td:nth-child(3) {
        padding: 5px 25px;
    }
}