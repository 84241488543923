.prospectus-page-comtainer {
	margin-top: 3%;
	margin-bottom: 3%;
}
.prospectus-page-table {
	width: 100%;
	text-align: start;
}
.prospectus-page-table tr th {
	border: 1px solid #000;
	padding: 10px 0px 10px 10px;
	background-color: #22549e;
	color: #fff;
}
.prospectus-page-table tr td {
	border: 1px solid #000;
	padding: 10px 0px 10px 10px;
	color: #22549e;
	font-weight: 600;
}
.link-cta {
	color: #fff;
}
.link-cta:hover {
	color: #fff;
	text-decoration:none;
}
.prospectus-page-pdf {
	text-align: center;
	padding: 10px 10px 10px 10px !important;
	color: #fff !important;
	background-color: #22549e;
}
@media (max-width: 575px) {
	.prospectus-page-table-container {
		overflow-x: auto;
	}
	.prospectus-page-table tr th {
		padding: 5px 8px 5px 8px;
		white-space: nowrap;
	}
	.prospectus-page-table tr td {
		white-space: nowrap;
	}
	.prospectus-page-table tr td {
		text-align: center;
		padding: 5px 8px 5px 8px;
		white-space: nowrap;
	}
	.prospectus-page-pdf {
		padding: 10px 25px !important;
	}
}
