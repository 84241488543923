.typewritter-container {
	background-color: #22549e;
	color: #ffee00;
	padding: 20px 0px 40px 0px;
	font-size: 20px;
}

@media (max-width: 575px) {
	.typewritter-container {
		padding: 20px 0px 15px 0px;
		font-size: 16px;
	}
}
.Typewriter {
	color: #fff;
	text-decoration: none;
}
.Typewriter:hover {
	opacity: 0.9;
	color: #fff;
}
