.academic-calendar-container{
    margin-top: 3%;
    margin-bottom: 3%;
}
table.academic-table {
    margin-left: auto;
    margin-right: auto;
}
table.academic-table tr th {
    border: 1px #000 solid;
    background-color: #22549E;
    color: #fff;
    padding: 10px 20px 10px 20px;
}
table.academic-table tr th:nth-child(2) {
    padding-right: 300px;
}
.academic-table tr td {
    border: 1px #000 solid;
    color: #22549E;
    font-weight: 600;
    padding: 10px 20px 10px 20px;
}
.academic-table tr td:nth-child(2){
    text-align: left;
}
.academic-calendar-pdf {
    background-color: #22549E;
}
.academic-calendar-pdf-view {
    color: #fff;
    font-size: 18px;
}
.academic-table tr td:nth-child(3) {
    cursor: pointer;
}
@media(max-width:575px) {
    .academic-calendar-table-container {
        overflow-x: auto;
    }
    table.academic-table tr th {
        padding: 8px 15px 8px 15px;
        white-space: nowrap;
    }
    .academic-table tr td:nth-child(2) {
        width: 63%;
    }
    table.academic-table tr th:nth-child(2) {
        padding-right: 100px;
    }
    table.academic-table {
        width: 100%;
    }
    .academic-table tr td {
        padding: 8px 15px 8px 15px;
        white-space: nowrap;
    }

}