.extend-the-pagex-heading {
    background-color: #008080;
    height: 60px;
}

.study-material-container {
    margin-bottom: 3%;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: -3%;
}

.study-material-search-content-row {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    justify-content: space-between;
}

.study-material-search-content-column {
    background-color: #22549e;
    color: #fff;
    padding-bottom: 5%;
}

.study-material-search-building {
    margin-top: 10%;
}

.study-material-search-building-icon {
    font-size: 45px;
}

.study-material-search-select-department-heading {
    margin-top: 8%;
}

.study-material-search-select-department-ul {
    display: inline-block;
}

.study-material-search-select-department-dropdown-list {
    color: #22549e;
    font-weight: 700;
    display: inline-block;
    width: 60%;
    border: none;
    outline: none;
    padding: 5px 5px;
}


.study-material-search-content-column {
    background-color: #22549e;
    color: #fff;
    padding-bottom: 5%;
}

.study-material-search-book {
    margin-top: 10%;
}

.study-material-search-book-icon {
    font-size: 45px;
}

.study-material-search-select-semester-heading {
    margin-top: 8%;
}

.study-material-search-select-department-ul {
    display: inline-block;
}

.study-material-search-select-semester-dropdown-list {
    color: #22549e;
    font-weight: 700;
    display: inline-block;
    width: 60%;
    border: none;
    outline: none;
    padding: 5px 5px;
}





.study-material-search-search {
    margin-top: 10%;
}

.study-material-search-search-icon {
    font-size: 45px;
}

.study-material-search-search-icon path {
    stroke: #fff;
}

.study-material-search-select-search-heading {
    margin-top: 8%;
}

.study-material-search-select-department-ul {
    display: inline-block;
}

.study-material-search-select-search-button {
    color: #22549e;
    font-weight: 700;
    display: inline-block;
    width: 60%;
    border: none;
    outline: none;
    padding: 5px 5px;
}

.hr-tag-between-search-result {
    margin-top: 4%;
    margin-bottom: 4%;
}

.result-for-search-files-row {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
}

.result-for-search-files-column {
    border: 1px solid;
    margin-bottom: 5%;
}

.result-for-search-files-heading-container {
    background-color: #22549e;
    padding: 25px 25px 15px 25px;
    text-align: start;
    color: #fff;
    display: grid;
    grid-template-columns: 29% 71%;
}

.result-for-search-files-heading-container p {
    font-weight: bolder;
    text-transform: uppercase;
    margin: 0;
}

.result-for-search-files-subject-heading-container {
    text-align: start;
    padding: 25px 25px 15px 25px;
    color: #22549e;
}

.result-for-search-files-subject-heading-container p {
    margin: 0;
    display: inline-block;
    padding-left: 10px;
    font-weight: bolder;
    text-transform: uppercase;
}

.result-for-search-files-download {
    padding: 25px 25px 25px 25px;
}

.result-for-search-files-download-btn {
    width: 100%;
    border: none;
    outline: none;
    background-color: #22549e;
    color: #fff;
    padding: 5px;
    font-size: 18px;
}

.result-for-search-files-download-btn-icon {
    margin-left: 2%;
    font-size: 18px;
}

@media(max-width:575px) {
    .study-material-container {
        margin-bottom: 5%;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: -5%;
    }

    .study-material-search-content-container {
        padding: 0px 2px;
    }

    .study-material-search-building-icon {
        font-size: 30px;
    }

    .study-material-search-select-department-ul li h4 {
        font-size: 12px;
    }

    .study-material-search-select-department-ul {
        text-align: start;
    }

    .study-material-search-book-icon {
        font-size: 34px;
    }

    .study-material-search-search-icon {
        font-size: 34px;
    }

    .result-for-search-files-row {
        display: grid;
        grid-template-columns: 100%;
    }

    .result-for-search-files-container {
        padding: 0px 10px;
    }

    .result-for-search-files-heading-container {
        background-color: #22549e;
        padding: 25px 8px 15px 10px;
        text-align: start;
        color: #fff;
        display: grid;
        grid-template-columns: 33% 67%;
    }
}