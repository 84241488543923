.co-curricular-card-container {
    margin-top: 3%;
    margin-bottom: 5%;
    padding: 0px 20px;
}
.co-curricular-card {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-bottom: 5%;
}
.co-curricular-card-parent-container {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: space-between;
}
.co-curricular-card-heading {
    text-align: center;
    background-color: #22549E;
    color: #fff;
    padding: 20px;
}
.co-curricular-card-heading h4 {
    margin-bottom: 0;
    font-size: 18px;
}
.co-curricular-date {
    margin-top: 2%;
}
.co-curricular-date p {
    margin-bottom: 0;
}
.co-curricular-event-content {
    margin-top: 3%;
    text-align: start;
    padding: 0px 10px;
    margin-bottom: 5%;
    min-height: 165px;
}
.co-curricular-view-more-btn {
    margin-bottom: 5%;
}
.co-curricular-view-more-btn .view-more-read-file-btn{
    border: none;
    background-color: #225493;
    color: #fff;
    padding: 10px 35px;
    outline: none;
    border-radius: 15px;
    font-size: 14px;
}
@media(max-width:575px) {
    .co-curricular-card-parent-container {
        grid-template-columns:100%;
    }
    .co-curricular-date {
        margin-top: 3.5%;
    }
}