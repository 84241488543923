.faq-page-container {
    margin-top: 3%;
    margin-bottom: 3%;
    padding-left: 30px;
    padding-right: 30px;
}
.faq-page-row-grid {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
}
.faq-page-single-column {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    min-height: 225px;
    margin-bottom: 10%;
}
.faq-pagequestion-container {
    background-color: #22549e;
    color: #fff;
    font-weight: 600;
    padding: 15px;
}
.faq-pagequestion-container p {
    margin: 0;
}
.faq-page-answer-container {
    text-align: start;
    padding: 15px;
}
@media(max-width:575px) {
    .faq-page-container{
        margin-top: 5%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .faq-page-row-grid {
        display: grid;
        grid-template-columns: 48% 48%;
    }
    .faq-pagequestion-container {
        padding: 5px 8px;
    }
    .faq-page-answer-container {
        padding: 5px 8px;
        overflow-x: auto;
    }
}