.upcoming-events-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.upcoming-events-image img {
    width: 100%;
}
.upcoming-events-table-container {
    margin-top: 2%;
}
table.upcoming-events-table {
    margin-left: auto;
    margin-right: auto;
}
.upcoming-events-table th {
    border: 1px #000 solid;
    color: #22549E;
    padding: 10px 55px;
    font-size: 18px;
}
.upcoming-events-table tr td {
    color: #22549E;
    font-size: 16px;
    font-weight: 700;
    border: 1px #000 solid;
    padding: 10px 55px;
    text-align: start;
}
.upcoming-events-pdf {
    background-color: #22549E;
    cursor: pointer;
}
.upcoming-events-pdf-eye {
    color: #fff;
    font-size: 20px;
}
@media(max-width:575px) {
    .upcoming-events-image img {
        width: 100%;
        height: 15rem;
        object-fit: fill;
        overflow: scroll;
    }
    .upcoming-events-table th {
        padding: 5px 10px;
        font-size: 15px;
        white-space: nowrap;
    }
    .upcoming-events-table tr td {
        padding:5px 10px;
        text-align: center;
        font-size: 13px;
        white-space: nowrap;
    }
    .upcoming-events-table-container {
        margin-top: 5%;
        overflow-x: auto;
    }
    .upcoming-events-pdf-eye {
        font-size: 18px;
    }
}