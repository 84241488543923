.admission-enquiry-row {
    display: grid;
    grid-template-columns: 20% 75%;
    justify-content: space-between;
}

.admission-enquiry-tabs-left-column {
    padding-top: 25%;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.admission-enquiry-left-tabs {
    background-color: #fff;
    color: #22549e;
    padding: 10px 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
.admission-enquiry-left-tabs h5 {
    margin: 0;
}
.active-admission-enquiry-left-tabs h5 {
    margin: 0;
}
.admission-enquiry-left-tabs a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #22549e;
    font-weight: 500;
    display: block;
}
.active-admission-enquiry-left-tabs {
    background-color: #22549e;
    color: #fff;
    padding: 10px 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.admission-enquiry-tables-right-column {
    padding-top: 6.5%;
    margin-bottom: 5%;
}

.admission-enquiry-tables {
    width: 90%;
    display: none;
}

.active-admission-enquiry-tables {
    width: 90%;
}

.active-admission-enquiry-tables p {
    text-align: start;
}

.active-admission-enquiry-tables ol li {
    text-align: start;
    margin-bottom: 0.4rem;
}

.active-admission-enquiry-tables strong {
    text-align: start;
    display: block;
}

.admission-enquiry-fees-structure-note {
    display: inline-block;
    float: left;
    margin-right: 5px;
    text-decoration: underline;
}

.admission-enquiry-fees-structure-table {
    width: 100%;
    margin-top: 3%;
}

.admission-enquiry-fees-structure-table tr td {
    border: 1px solid #000;
    padding: 10px 20px;
}

.admission-enquiry-fees-structure-table tr td:nth-child(1) {
    border: 1px solid #000;
    padding: 10px 20px;
    text-align: start;
    font-weight: 600;
    color: #22549e;
    font-size: 18px;
}

.admission-enquiry-fees-structure-table tr td a {
    color: #fff;
    font-size: 20px;
    display: block;
}

.admission-enquiry-fees-structure-table tr td a .admission-enquiry-fees-structure-table-pdf-view {
    margin-bottom: 4px;
}

.admission-enquiry-fees-structure-table tr td:nth-child(2) {
    background-color: #22549e;
    color: #fff;
}
.admission-enquiry-eligiblity-criteria-container p {
    text-align: start;
}
.admission-enquiry-eligiblity-criteria-container ul li {
    text-align: start;
    margin-bottom: 0.5rem;
}

@media(max-width:575px) {
    .admission-enquiry-row {
        display: grid;
        grid-template-columns: 100%;
    }

    .admission-enquiry-tabs-left-column {
        margin-top: 5%;
        display: flex;
        padding: 0% 2%;
        overflow: auto;
        white-space: nowrap;
        border: none;
    }
    .admission-enquiry-left-tabs {
        padding: 10px 10px;
    }
    .active-admission-enquiry-left-tabs{
        padding: 10px 10px;
    } 
    .admission-enquiry-left-tabs h5 {
        font-size: 1rem;
    }

    .active-admission-enquiry-left-tabs h5 {
        font-size: 1rem;
    }

    .admission-enquiry-tables-right-column {
        overflow-x: auto;
    }

    .active-admission-enquiry-tables {
        width: 100%;
    }
    .admission-enquiry-left-tabs a {
        font-size: 1rem;
    }

    .active-admission-enquiry-left-tabs a {
        font-size: 1rem;
    }
    .admission-enquiry-fees-structure-table tr td {
        padding: 5px 10px;
    }
    .admission-enquiry-fees-structure-table tr td:nth-child(1) {
        padding: 5px 10px;
    }
}