.privacy-policy-container {
    margin-top: 3%;
    margin-bottom: 3%;
}
.privacy-policy {
    text-align: start;
}
.privacy-policy-heading {
    margin-bottom: 2%;
    background-color: #22549e;
    padding: 10px ;
}
.privacy-policy-heading .privacy-policy-title {
    margin: 0;
    text-align: center;
    font-weight: 500;
    color: #fff;
}