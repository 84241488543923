.certificate-course-container {
    margin-top: 3%;
    margin-bottom: 3%;
}
.certificate-course-image-container img{
    width: 100%;
}
.certificate-course-table-container {
    margin-top: 3%;
}
.certificate-course-table1-container .certificate-course-table1 {
    margin-left: auto;
    margin-right: auto;
}
.certificate-course-table1 tr td {
    border: 1px solid #000;
    color: #22549e;
    padding: 10px; 
}
.certificate-course-table1 tr td:nth-child(1){
    font-weight: 600;
    font-size: 20px;
    width: auto;
}
.certificate-course-table1 tr td:nth-child(2){
    font-weight: 500;
    font-size: 20px;
    text-align: start;
    padding-left: 20px;
    width: 73%;
}
.certificate-course-table1 tr td:nth-child(3){
    background-color: #22549e;
    color: #fff;
    cursor: pointer;
    width: 22%;
}
.certificate-course-table1 tr td a {
    color: #fff;
    font-size: 20px;
    display: block;
}


.certificate-course-table2-container .certificate-course-table2 {
    width: 100%;
}
.certificate-course-table2 tr td {
    border: 1px solid #000;
    color: #22549e;
    padding: 10px; 
}
.certificate-course-table2 tr td:nth-child(1){
    font-weight: 600;
    font-size: 20px;
}
.certificate-course-table2 tr td:nth-child(2){
    font-weight: 500;
    font-size: 20px;
    text-align: start;
    padding-left: 20px;
}
.certificate-course-table2 tr td:nth-child(3){
    background-color: #22549e;
    color: #fff;
    cursor: pointer;
}
.certificate-course-table2 tr td a {
    color: #fff;
    font-size: 20px;
    display: block;
}
@media(max-width:575px) {
    .certificate-course-table1-container {
        overflow: auto;
    }
    .certificate-course-table1 tr td {
        white-space: nowrap;
        padding: 5px 10px;
        font-size: 16px !important;
    }
    .certificate-course-table1 tr td:nth-child(2) {
        padding-left: 10px;
    }
}