.master-management-imgage {
	margin-top: 3%;
}

.master-management-imgage img {
	width: 100%;
}

.master-management-content h5 {
	text-align: start;
	margin-top: 1%;
}

.master-management-content p {
	text-align: start;
}

.objective-curriculum-heading {
	margin-top: 2%;
}

.objective-curriculum-heading h6 {
	background-color: #22549e;
	color: #fff;
	padding: 16px;
}

.objctive-curriculum-content {
	padding: 10px 15px 30px 15px;
	text-align: start;
	background-color: rgb(217, 217, 217, 0.2);
}

.learning-pedagogy-heading {
	margin-top: 2%;
}

.learning-pedagogy-heading h6 {
	background-color: #22549e;
	color: #fff;
	padding: 16px;
}

.learning-pedagogy-content {
	padding: 10px 15px 30px 15px;
	text-align: start;
	background-color: rgb(217, 217, 217, 0.2);
}

.master-management-buttons {
	display: grid;
	padding: 5%;
	grid-template-columns: 50% 50%;
	column-gap: 4%;
	justify-content: space-around;
}

.mms-dyanmic-btn {
	background-color: #22549e;
	color: #fff;
	font-weight: 600;
	padding: 15px 37px;
	margin-bottom: 6%;
	border: none;
	outline: none;
	box-shadow: 10px -10px 0px rgb(255 238 0);
	border: none;
	outline: none;
	transition: 0.4s;
	text-decoration: none;
}
.mms-dyanmic-btn:hover {
	background-color: #008080;
}

.mms-dyanmic-btn p {
	margin: 0;
	text-decoration: none;
	color: #fff;
	display: block;
}

@media (max-width: 575px) {
	.master-management-imgage {
		margin-top: 5%;
	}

	.master-management-imgage img {
		width: 100%;
		height: 15rem;
		object-fit: fill;
	}

	.master-management-content h5 {
		margin-top: 3%;
	}

	.objective-curriculum-heading h6 {
		margin: 0;
		padding: 12px;
	}

	.objctive-curriculum-content ol {
		padding-left: 1.1rem;
	}

	.objctive-curriculum-content {
		padding: 10px 15px 15px 15px;
	}

	.learning-pedagogy-heading h6 {
		margin: 0;
		padding: 12px;
	}

	.master-management-buttons {
		display: grid;
		padding: 5%;
		grid-template-columns: 48% 48%;
		column-gap: 6%;
		justify-content: space-between;
	}
	.mms-dyanmic-btn {
		padding: 9px 14px;
		margin-bottom: 15%;
		box-shadow: 8px -7px 0px rgb(255 238 0);
	}
	.mms-dyanmic-btn a {
		font-size: 14px;
	}
}
