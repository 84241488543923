.consulting-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.consulting-content p {
    text-align: start;
}
.consulting-image-container {
    margin-top: 2%;
}
.consulting-image-container img {
    width: 100%;
}
@media(max-width:575px) {
    
}