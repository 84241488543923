@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.footer-container {
    background-color: #008080;
    padding: 50px 25px;
}

.footer-row::after {
    content: "";
    display: table;
    clear: both;
}

.footer-column {
    float: left;
    width: 24%;
    padding-right: 10px;
}

.footer-column ul li {
    list-style: none;
    margin-bottom: 4%;
    color: #fff;
    font-size: 16px;
    text-align: start;
    line-height: 19px;
}
.footer-column ul li:hover {
    color: #ffee00;
}
.footer-column ul li a:hover {
    color: #ffee00;
}
.footer-column.column2 {
    border-right: 1px #fff solid;
}

.hrfirst {
    width: 38%;
    float: left;
    margin-top: 15px;
    opacity: 1 !important;
    color: #fff;
}

.hrsecond {
    float: right;
    width: 42%;
    margin-top: 15px;
    opacity: 1 !important;
    color: #fff;
}

.social-media-links {
    color: #fff !important;
    font-size: 20px;
    float: left;
    margin-right: 1%;
}

.social-media-links.linkedin {
    margin-right: 0;
}

.social-media-links.whatsapp {
    margin-left: 1%;
}

.footer-icons.social-media-icons {
    padding-bottom: 2%;
    margin-top: 2%;
}

.all-right-reserved-container {
    text-align: center;
    padding: 20px;
}

.all-right-reserved-container a {
    color: #fff;
    text-decoration: none;
}

.footer-column ul li a {
    color: #fff;
    text-decoration: none;
}
.social-media-links .social-container .social-svg .social-svg-mask {
    fill: #fff !important;
}
.social-media-links:hover .social-container .social-svg .social-svg-mask {
    fill: #FFEE00 !important;
}
.visitors-count-image {
    margin-top: 15px;
}
@media(min-width: 768px) and (max-width: 991.98px) {
    .footer-column {
        width: 23%;
    }

    .hrfirst {
        width: 36%;
    }

    .hrsecond {
        width: 36%;
    }

    .footer-column ul li {
        margin-bottom: 6%;
    }

    .all-right-reserved-container {
        margin-top: 3%;
    }
    
}

@media(max-width:575px) {
    .footer-container {
        padding: 10px 0px;
    }

    .footer-column {
        width: 100%;
        border-bottom: 1px solid rgb(255, 255, 255, .4);
    }

    .footer-column ul {
        padding-left: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .footer-column ul li {
        font-size: 16px;
        margin-top: 3.5%;
        margin-bottom: 3.5%;
    }
    .hrfirst {
        width: 20%;
    }

    .hrsecond {
        width: 20%;
    }
    .footer-icons.social-media-icons {
        margin-top: 7%;
    }
    .social-media-links.facebook {
        margin-left: 0%;
    }
    .social-media-links.whatsapp {
        margin-left: 3%;
    }

    .all-right-reserved-container {
        margin-top: 10%;
        padding: 0px;
    }

    .footer-column.column2 {
        border-right: none;
    }
    .footer-icons.social-media-icons {
        margin-top: 10%;
    }
}