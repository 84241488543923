.core-faculty-container {
	margin-bottom: 5%;
}

.core-faculty-profile-container {
	background-color: #008080;
	height: 250px;
	width: 100%;
}

.core-faculty-profile-image {
	position: relative;
	text-align: start;
}

.core-faculty-profile-image .core-faculty-details-image {
	position: absolute;
	width: 150px;
	top: 115px;
	border-radius: 100%;
	left: 9%;
}

.core-faculty-prfile-name {
	position: relative;
}

.core-faculty-prfile-name .core-faculty-name-email {
	position: absolute;
	left: 22%;
	top: 146px;
	width: 68%;
}

.core-faculty-name-email h3 {
	text-align: start;
	margin: 0;
	color: #fff !important;
}

/* .core-faculty-name-email .social-icon.social-media-links.facebook {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    margin-top: 1%;
}
.core-faculty-name-email .social-icon.social-media-links.linkedin {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    margin-top: 1%;
    margin-left: 1%;
}

.core-faculty-name-email strong {
    display: inline-block;
    float: left;
    margin-left: 1%;
    text-align: center;
    padding-top: 3px;
    margin-top: 1%;
    color: #fff !important;
}
.core-faculty-name-email p {
    margin: 0px;
    display: inline;
    float: left;
    margin-left: 1%;
    margin-top: 1%;
    color: #fff !important;
    padding-top: 2px;
} */
/* .faculty-social-details-container {
	margin-top: 1%;
} */

.faculty-social-details-email-container {
	display: grid;
	grid-template-columns: 100%;
	justify-content: space-between;
	float: left;
	width:100%;
	text-align: start;
}

.faculty-social-details-email-container strong {
	margin-left: 1%;
	text-align: center;
	padding-top: 3px;
	margin-top: 1%;
	padding-left: 2%;
	color: #fff !important;
}

.faculty-social-details-email-container p {
	margin: 0px;

	color: #fff !important;
	padding-top: 2px;
}

.faculty-social-details-container .faculty-social-media {
	margin-left: 0;
	margin-top: 5px;
}

.core-faculty-details-experience {
	margin-top: 5%;
	padding: 0px;
}

.core-faculty-details-experience p {
	text-align: start;
	margin: 0px;
}

.core-faulty-detail-qualification-heading {
	background-color: #22549e;
	color: #fff;
	font-weight: 600;
	font-size: 18px;
	padding: 10px;
	margin-top: 2%;
}

.core-faulty-detail-qualification-heading p {
	margin: 0px;
}

.core-faulty-detail-qualification-content p {
	text-align: left;
}
.core-faulty-detail-qualification-content {
	background-color: rgb(217, 217, 217, 0.2);
	padding: 1% 0% 2% 0%;
	text-align: left;
}

.core-faulty-detail-qualification-content ul li {
	text-align: start;
	margin-left: 2%;
}

.core-faculty-details-pdf-container {
	display: grid;
	grid-template-columns: 50% 50%;
	row-gap: 25px;
	margin-top: 3%;
}

.core-faculty-pasr-experience {
	border: 1px solid #000;
	color: #22549e;
	font-weight: 600;
	padding: 6px;
	display: inline-block;
	width: 60%;
	text-align: start;
}

.core-faculty-pasr-experience p {
	margin: 0px;
}

.core-faculty-expierence-pdf {
	background: #22549e;
	width: 14%;
	padding: 5.2px 0px 5.3px 0px;
	border: none;
	outline: none;
	font-size: 18px;
	color: #fff;
	cursor: pointer;
}
.break-tag-hidden-desktop {
	display: none;
}
.core-faculty-expierence-pdf-view {
	color: #fff;
}
@media (max-width: 575px) {
	.core-faculty-profile-container {
		height: 135px;
	}

	.core-faculty-profile-image .core-faculty-details-image {
		width: 30%;
		left: 2.5%;
		top: 36px;
	}

	.core-faculty-prfile-name .core-faculty-name-email {
		top: 20px;
		right: 1px;
		left: unset;
		padding-left: 10px;
	}

	.core-faculty-prfile-name .core-faculty-name-email h3 {
		font-size: 1.2rem;
	}

	.core-faculty-details-experience {
		padding: 0px 15px;
		margin-top: 10%;
	}

	.break-tag-hidden-desktop {
		display: block;
	}

	.faculty-social-details-container {
		margin-top: 2%;
	}

	.faculty-social-details-container .faculty-social-media.facebook {
		margin-left: 15%;
	}

	.faculty-social-details-email-container {
		display: grid;
		grid-template-columns: 100%;
		justify-content: space-between;
		float: left;
		width:100%;
	}
	.core-faculty-details-pdf-container {
		grid-template-columns: 100%;
	}
	.faculty-social-details-email-container p {
		word-break: break-word;
		font-size: 13px;
	}
}
