.exam-time-table-container {
    margin-top: 3%;
    margin-bottom: 5%;
}

.exam-time-table table {
    margin-left: auto;
    margin-right: auto;
}

.exam-time-table table th {
    background-color: #22549E;
    border: 1px #000 solid;
    color: #fff;
    padding: 15px 30px 15px 30px;
}

.exam-time-table table tr td {
    border: 1px #000 solid;
    padding: 15px 30px 15px 30px;
    color: #22549E;
    font-weight: 700;
}

.exam-time-table table tr td.time-table-course-name {
    font-size: 14px;
    text-align: start;
}

.view-time-table-pdf-eye {
    color: #fff;
    font-size: 22px;
}

.view-time-table-pdf {
    background-color: #22549E;
}
.view-time-table-pdf a {
    text-decoration:none;
    color:#fff;
}
@media(max-width:575px) {
    .exam-time-table {
        overflow: auto;
    }
    .exam-time-table table {
        width: 100%;
    }
    .exam-time-table table th {
        padding: 8px 10px 8px 10px;
        white-space: nowrap;
    }

    .exam-time-table table tr td {
        padding: 8px 10px 8px 10px;
        white-space: nowrap;
    }
    .exam-time-table table tr td:nth-child(1) {
        text-align: start;
    }
    .view-time-table-pdf-eye {
        font-size: 18px;
    }
}