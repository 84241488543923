.mms-syllabus-container {
    margin-top: 3%;
    margin-bottom: 5%;
}

table.mms-syllabus-table {
    margin-left: auto;
    margin-right: auto;
}

.mms-syllabus-table tr td {
    border: 1px #000 solid;
    color: #22549E;
    font-weight: 600;
    padding: 10px 20px 10px 20px;
}

.mms-syllabus-table tr td:nth-child(2) {
    padding: 10px 150px 10px 20px;
    text-align: start;
}

.mms-syllabus-table tr td:nth-child(3) {
    padding: 10px 50px 10px 50px;
    background-color: #22549E;
    cursor: pointer;
}

.mms-syllabus-pdf-view {
    font-size: 18px;
    color: #fff;
}

@media(max-width:575px) {
    .mms-syllabus-table-container {
        overflow-x: auto;
    }
    table.mms-syllabus-table {
        width: 100%;
    }

    .mms-syllabus-table tr td {
        padding: 5px 10px;
        white-space: nowrap;
    }

    .mms-syllabus-table tr td:nth-child(2) {
        padding: 5px 10px 10px 8px;
        text-align: start;
        font-size: 15px;
    }

    .mms-syllabus-table tr td:nth-child(3) {
        padding: 5px 10px 10px 10px;
        background-color: #22549E;
        width: 15%;
    }
}