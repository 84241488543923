.committee-name-role{
    margin-top: 3%;
    padding: 0 5%;
    margin-bottom: 5%;
}
.committee-name-role {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
} 
.committee-name-role-profile.column {
    background-color: #FFEE00;
    border-radius: 20px;
    margin-top: 8%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition: .5s;
}
.committee-name-role-profile.column:hover {
    transform: scale(1.1,1.1);
}
.committee-name-role-profile.column:hover .comittee-member-details p {
    color: #008080;
}
.comittee-member-profile-img img {
    border-radius: 100%;
    padding-top: 2%;
    width:55%;
    height: 200px;
    object-fit: fill;
}
.comittee-member-details {
    margin-top: 5%;
    margin-bottom: 8%;
}
.comittee-member-details p {
    margin: 0;
}
.comittee-member-details .comittee-member-name {
    margin: 0;
    font-weight: 500;
}


.college-development-committee-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.college-development-commite-table {
    margin-left: auto;
    margin-right: auto;
}
.college-development-commite-table tr th {
    border: 1px solid #000;
    color: #fff;
    font-weight: 600;
    padding: 10px 20px;
    background-color: #22549e;
}
.college-development-commite-table tr th:nth-child(1){
    width: 10%;
}
.college-development-commite-table tr th:nth-child(2){
    width: 60%;
}
.college-development-commite-table tr th:nth-child(1){
    width: 10%;
}
.college-development-commite-table tr td:nth-child(2){
    width: 60%;
    text-align: start;
    padding-left: 20px;
}
.college-development-commite-table tr td {
    border: 1px solid #000;
    color: #22549e;
    font-weight: 600;
    padding: 10px 20px;
}
@media(max-width:575px) {
    .committee-name-role {
        grid-template-columns: 90%;
        justify-content: space-around;
    }
    .committee-name-role.row {
        padding: 0%;
    }
    .comittee-member-profile-img img {
        width: 62%;
        padding-top: 4%;
        object-fit: fill;
        height: 210px;
    }
    .comittee-member-details p:nth-child(2){
        font-size: 14px;
        margin-top: 5px;
        padding: 0 5px;
    }
    .comittee-member-details p:nth-child(3){
        font-size: 14px;
    }



    .college-development-committee-container{
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .college-development-commite-table-container {
        overflow: auto;
    }
    .college-development-commite-table tr th {
        padding: 5px 10px;
        white-space: nowrap;
    }
    .college-development-commite-table tr td {
        padding: 5px 10px;
        white-space: nowrap;
    }
}