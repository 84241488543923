.bridge-course-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.bride-course-heading h4 {
    color: #22549E;
    text-align: start;
    font-weight: bolder;
}
.bridge-course-about-heading {
    background-color: #22549E;
    padding: 10px 10px;
    text-align: center;
}
.bridge-course-about-heading p {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0 !important;
}
.bridge-course-about-content {
    padding: 10px 15px;
    margin-bottom: 2%;
    background-color: rgb(217, 217, 217,0.2)
}
.bridge-course-about-content p {
    text-align: start;
    margin-bottom: 0 !important;
}
.associate-file-container {
    margin-top: 2%;
    display: block;
    text-align: start;
}
.associate-file-heading {
    display: inline-block;
    border: 1px #000 solid;
    padding: 10px 15px;
    width: 25%;
}
.associate-file-heading p {
    margin-bottom: 0 !important;
    color: #22549E;
    font-weight: 700;
}
.associate-file-pdf-button {
    width: 8%;
    padding: 10px 22px;
    outline: none;
    background: #22549E;
    border: 1px solid;
}
.associate-file-pdf-button .associate-file-pdf-view {
    color: #fff;
    font-size: 20px;
}
@media(max-width:575px){
    .bridge-course-about-heading {
        padding: 5px 10px;
    }
    .bridge-course-about-heading p {
        font-size: 17px;
    }
    .associate-file-heading {
        padding: 5px 10px;
        width: 55%;
    }
    .associate-file-pdf-button {
        padding: 5px 10px;
        width: 15%;
    }
    .bride-course-heading {
        margin-bottom: 3%;
    }
    .bride-course-heading h4 {
        margin: 0;
    }
}