.best-practices-boot-camp-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.best-practices-boot-camp-table-container {
    overflow: auto;
}
.best-practices-boot-camp-table {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.best-practices-boot-camp-table tr td {
    border:1px solid #000;
    padding: 10px 13px;
    font-size: 20px;
    font-weight: 600;
    color: #22549e;
}
.best-practices-boot-camp-table tr td:nth-child(1) {
    width: 50px;
}
.best-practices-boot-camp-table tr td:nth-child(2) {
    text-align: start;
    padding-left:20px ;
}
.best-practices-boot-camp-table tr td:nth-child(3) {
    background-color: #22549e;
    color: #fff;
    width: 150px;
}
.best-practices-boot-camp-table tr td:nth-child(3) a {
    color: #fff;
    display: block;
}