.students-achievements-container {
    margin-top: 3%;
    margin-bottom: 5%;
    padding: 0px 2%;
}

.students-achievements {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
}

.students-achievements-columns {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-bottom: 5%;
}

.students-achievements-table {
    width: 100%;
}

.students-achievements-table caption {
    padding: 23px;
    font-size: 18px;
}

.students-achievements-table tr th {
    border: 1px solid #000;
    padding: 10px
}

.students-achievements-table tr td {
    border: 1px solid #000;
    padding: 10px
}

.students-achiievements-mentors-heading {
    text-align: start;
    margin-left: 3%;
    margin-top: 2%;
}

.students-achiievements-mentors-heading h4 {
    margin: 0;
    display: inline-block;
    font-size: 18px;
}

.students-achiievements-mentors-heading p {
    margin: 0;
    display: inline-block;
    margin-left: 2%;
}

.students-achiievements-award {
    margin-left: 3%;
    text-align: start;
    padding-right: 2%;
    margin-top: 2%;
}
@media(max-width:575px){
    .students-achievements {
        grid-template-columns: 100%;
        padding: 0px 10px;
    }
    .students-achievements-table caption {
        padding: 10px;
        font-size: 16px;
    }
    .students-achievements-table tr th{
        padding: 7px;
    }
    .students-achievements-table tr td {
        padding: 7px;
    }
    .students-achievements-columns{
        margin-bottom: 8%;
    }
}