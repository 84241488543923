.research-journal-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.research-journal-heading h3 {
    color: #22549e;
}
.research-journal-content {
    text-align: start;
    margin-top: 2%;
    margin-bottom: 4%;
}
.research-journal-guideline-heading h4 {
    color: #22549e;
}
.research-journal-guideline-note {
    background-color: #22549e;
    padding: 5px 15px;
}
.research-journal-guideline-note p {
    color: #fff;
    margin-bottom: 0px;
}
.research-journal-points {
    background-color: rgb(217, 217, 217,0.2);
    padding-top: 15px;
    padding-bottom: 25px;
}
.research-journal-points ul li {
    text-align: start;
}
@media(max-width:575px) {
   .research-journal-heading h3 {
    font-size: 1.3rem;
    margin: 0;
   } 
   .research-journal-content {
    margin-top: 3%;
   }
   .research-journal-guideline-note {
    text-align: start;
   }
   .research-journal-points ul {
    padding-right: 10px;
   }
}
