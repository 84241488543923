.core-faculty-name-role.row {
	margin-top: 3%;
	padding: 0 5%;
	margin-bottom: 5%;
}
.core-faculty-name-role.row {
	display: grid;
	grid-template-columns: 30% 30% 30%;
	justify-content: space-between;
}
.core-faculty-name-role-profile.column {
	background-color: #ffee00;
	border-radius: 20px;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	height: 100%;
	transition: 0.3s ease-in-out;
}
.core-faculty-name-role-profile.column:hover {
	transform: scale(1.1, 1.1);
}
.core-faculty-name-role-profile.column:hover
	.core-faculty-details
	.core-faculty-name {
	color: #008080;
}
.core-faculty-profile-img img {
	border-radius: 100%;
	padding-top: 2%;
	width: 55%;
	height: 100%;
	object-fit: fill;
}
.core-faculty-details {
	margin-top: 5%;
	margin-bottom: 8%;
}
.core-faculty-details p {
	margin: 0;
}
.core-faculty-name-role.row a {
	text-decoration: none;
	color: #000 !important;
	margin-top: 8%;
}
.core-faculty-details .core-faculty-name {
	margin: 0;
	font-weight: 500;
	padding: 0px 10px;
}
@media (max-width: 575px) {
	.core-faculty-name-role.row {
		grid-template-columns: 100% !important;
	}
	.core-faculty-profile-img img {
		width: 53%;
		height: 100%;
	}
}
