.college-prospectus-row::after {
    content: "";
    display: table;
    clear: both;
}
.college-prospectus-row .columns{
    float: left;
    width: 25%;
    display: inline-flex;
    padding: 20px 0px 6px 15px;
}
.college-icons {
    font-size: 35px;
    display: flex;
    color: #22549E;
}
.college-prospectus-content {
    margin-left: 5%;
    text-align: start;
}
.college-prospectus-content h3 {
    /* margin-top: 5px; */
    margin-bottom: 0px;
    font-size: 16px;
}
.college-prospectus-content a {
    text-decoration: none;
    color: #000;
    text-align: start;
    font-size: 15px;
}
.take-div-upp {
    max-width: 1080px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2%;
    background-color: #ffff;
    /* padding: 20px 0px 20px 0px; */
    -webkit-border-radius: 3px 3px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 45%);
    margin-bottom: 3%;
}
.college-prospectus-row {
    /* padding: 20px 10px 20px 10px; */
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .take-div-upp {
        margin: -2% 5% 3% 5%;
    }
    .college-prospectus-row .columns {
        padding: 10px 0px 0px 0px;
    }
    .college-prospectus-content {
        margin-left: 4%;
    }
    .college-prospectus-content h3 {
        font-size: 15px;
    }
}
@media(max-width:575px) {
    .take-div-upp {
        margin-left: 5%;
    margin-top: 4%;
    background-color: #ffff;
    padding: 20px 0px 20px 0px;
    -webkit-border-radius: 3px 3px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 45%);
    margin-right: 5%;
    margin-bottom: 5%;
    }
    .college-prospectus-row .columns {
        width: 100%;
        margin-bottom: 5%;
    }
    .college-prospectus-content {
        margin-left:5%;
    }
}