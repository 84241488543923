.development-initiative-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.development-initiative-buttons {
    display: grid;
    grid-template-columns: 50% 50%;
}
.development-button {
    margin-bottom: 6%;
}
.development-button a button {
    background-color: #22549E;
    color: #fff;
    font-weight: 600;
    box-shadow: 10px -10px 0px rgb(255 238 0);
    padding: 15px 20px;
    width: 80%;
    font-size: 20px;
    border: none;
    transition: .4s;
}
@media(max-width:575px) {
    
}