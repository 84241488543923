.event-registration-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.alumni-registration-table-container .alumni-registration-table {
    width: 100%;
}
.alumni-registration-table tr td {
    border: 1px solid #000;
    padding: 10px 0px;
    text-align: center;
    font-weight: 600;
    color: #22549e;
}
.alumni-registration-table tr td:nth-child(2) {
    text-align: start;
    padding-left: 20px;
}
.alumni-registration-table tr td:nth-child(3) {
    width: 15%;
    background-color: #22549e;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
} 
.alumni-registration-pdf .alumni-registration-pdf-view {
    display: block;
    margin: auto;
    font-size: 20px;
}
.student-registration-table-container {
    margin-top: 3%;
}
.student-registration-table-container .alumni-registration-table {
    width: 100%;
}
@media(max-width:575px) {
    .event-registration-container{
        margin-top: 5%;
    }
    .alumni-registration-table-container {
        overflow-x: auto;
    }
    .alumni-registration-table tr td {
        padding: 5px 0px;
        white-space: nowrap;
    }
    .alumni-registration-table tr td:nth-child(2) {
        padding-left: 10px;
    }
    .alumni-registration-table tr td:nth-child(3){
        padding: 5px 10px;
        font-size: 12px;
    }
    .alumni-registration-pdf .alumni-registration-pdf-view {
        font-size: 18px;
    }
    .student-registration-table-container {
        margin-top: 5%;
    }
    .student-registration-table-container {
        overflow-x: auto;
    }
}