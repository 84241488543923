.home-page-admission-panel-container {
    position: fixed;
    top: 55%;
    right: -70px;
    box-shadow: 0 0 8px gray;
    z-index: 20;
    transform: rotate(270deg);
    padding: 5px 14px;
    background-color: #ff0e0e;
    cursor: pointer;
}

.home-page-admission-panel-heading {
 color: #fff;   
 font-size: 1.4rem;
}
@media(max-width:575px){
    .home-page-admission-panel-container {
        position: fixed;
        top: 55%;
        right: -60px;
        box-shadow: 0 0 8px gray;
        z-index: 10;
        transform: rotate(270deg);
        padding: 5px 14px;
        background-color: #ff0e0e;
    }
    .home-page-admission-panel-heading {
        font-size: 1.2rem;
        margin-bottom: 0.2rem;
    }
}