.accolades-container {
    margin-top: 3%;
    margin-bottom: 5%;

}
.accolades-image-container img{
    width: 100%;
    height: 100%;
}
@media(max-width:575px){
    .accolades-container {
        margin-top: 5%;
    }
}