.welcome-message-container {
    padding: 100px 100px;
    background-color: #22549E;
}
.welcome-message-row::after {
    content: "";
    display: table;
    clear: both;
}
.welcome-message.column1 {
    width: 40%;
    float: left;
    text-transform: uppercase;
    color: #fff;
    text-align: start;
}
.welcome-message.column2 {
    width: 55%;
    float: left;
    text-align: start;
    padding-left: 5%;
    color: #fff;
}
.welcome-message.column2 p {
    margin: 0;
}
.welcome-message.column1 h1{
    margin: 0;
    font-size: 25px;
}

@media(min-width: 768px) and (max-width: 991.98px) {
    .welcome-message-container{
        padding: 50px 50px;
    }
    .welcome-message.column2 {
        font-size: 18px;
    }   
}
@media(max-width:575px) {
    .welcome-message-container {
        padding: 20px 20px;

    }
    .welcome-message.column1 {
    width: 100%;
    text-align: start;
    }
    
    .welcome-message.column2 {
        width: 100%;
        padding-left: 0px;
    }
    .welcome-message.column2 .first-para {
        margin-top: 5%;
    }
}