.organisational-chart-container {
    margin-top: 3%;
}
.organisational-chart-heading h2 {
    color: #008080;
    margin-bottom: 2%;
    text-align: start;
}
.organisational-content p{
    text-align: start;
}
.organisational-chart-image img {
    width: 100%;
    border:8px double #008080;
}
.governing-body-table {
    margin-top: 3%;
    margin-bottom: 5%;
}
@media(max-width:575px) {
    .organisational-chart-image img {
        width: 100%;
        height: 15rem;
        object-fit: fill;
    }
    .committee-name-role {
        padding: 0%;
    }
    .governing-body-table {
        margin-top: 5%;
        margin-bottom: 5%;
    }
}