.feedback-page-row {
    display: grid;
    grid-template-columns: 20% 75%;
    justify-content: space-between;
}

.feed-back-tabs-left-column {
    padding-top: 25%;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.feed-back-left-tabs {
    background-color: #fff;
    color: #22549e;
    padding: 10px 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.active-feed-back-left-tabs {
    background-color: #22549e;
    color: #fff;
    padding: 10px 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.feed-back-tables-right-column {
    padding-top: 6.5%;
    margin-bottom: 5%;
}

.feed-back-tables {
    width: 90%;
    display: none;
}

.active-feed-back-tables {
    width: 90%;
}

.active-feed-back-tables tr th {
    border: 1px solid #000;
    padding: 10px;
    background-color: #22549e;
    color: #fff;
}

.active-feed-back-tables tr th:nth-child(4) {
    padding: 10px 12px;
}

.active-feed-back-tables tr td {
    border: 1px solid #000;
    padding: 10px;
    font-weight: 600;
}

.active-feed-back-tables tr td:nth-child(4) {
    padding: 10px 12px;
    background-color: #22549e;
    color: #fff;
    cursor: pointer;
}
.feed-back-pdf a{
 color: #fff;
}
@media(max-width:575px) {
    .feedback-page-row {
        display: grid;
        grid-template-columns: 100%;
    }

    .feed-back-tabs-left-column {
        margin-top: 5%;
        display: flex;
        padding: 0% 2%;
        overflow: auto;
        white-space: nowrap;
        border: none;
    }
    .feed-back-left-tabs h5 {
        font-size: 1rem;
    }
    .active-feed-back-left-tabs h5{
        font-size: 1rem;
    }
    .feed-back-tables-right-column {
        overflow-x: auto;
    }
    .active-feed-back-tables {
        width: 100%;
    }
    .active-feed-back-tables tr th {
        padding: 5px 10px;
        white-space: nowrap;
    }
    .active-feed-back-tables tr td {
        padding: 5px 10px;
        white-space: nowrap;
    }
    .active-feed-back-tables tr td:nth-child(4) {
        padding: 10px 25px;
    }
}