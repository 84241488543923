.student-welfare-services-container {
    margin-top: 3%;
    margin-bottom: 3%;
    padding: 0 4% 0 4%;
}
.student-welfare-services-banner-1 img {
    width: 100%;
}
.student-welfare-services-heading {
    margin-top: 2%;
    text-align: start;
    color: #22549e;
}
.student-welfare-services-content {
    text-align: start;
    color: #22549e;
}
.student-welfare-services-banner-2 img {
    margin-top: 2%;
    width: 100%;
}
.student-welfare-services-banner-3 img {
    margin-top: 2%;
    width: 100%;
}
@media(max-width:575px) {
    .student-welfare-services-banner-1 img {
        width: 100%;
        height: 15rem;
        object-fit: fill;
    }
    .student-welfare-services-banner-2 img {
        width: 100%;
        height: 15rem;
        object-fit: fill;
        margin-top: 3%;
    }
    .student-welfare-services-banner-3 img {
        width: 100%;
        height: 15rem;
        object-fit: fill;
        margin-top: 3%;
    }
}