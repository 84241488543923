.academic-research-paper-dynamic-container.container {
	margin-top: 3%;
	margin-bottom: 5%;
	min-height: 300px;
}
.academic-research-paper-dynamic-heading {
	text-align: start;
}
.academic-research-paper-dynamic-single-box {
	border: 2px solid #22549e;
	border-radius: 10px;
	margin-bottom: 30px;
}
.academic-research-paper-dynamic-single-box-heading {
	display: grid;
	grid-template-columns: 33% 33% 33%;
	justify-content: space-between;
	text-align: start;
	padding: 5px 15px;
	background-color: #22549e;
}
.academic-research-paper-dynamic-single-box-heading-first p {
	display: inline;
	margin: 0;
	color: #fff;
}
.academic-research-paper-dynamic-single-box-heading-first strong {
	color: #fff;
}
.academic-research-paper-dynamic-single-box-content-container {
	margin-top: 5px;
	display: grid;
	grid-template-columns: 33% 33% 33%;
	justify-content: space-between;
	text-align: start;
	padding: 0px 15px;
}
.academic-research-paper-dynamic-single-box-content-container
	.academic-research-paper-dynamic-single-box-heading-first
	p {
	color: #22549e;
}
.academic-research-paper-dynamic-single-box-content-container
	.academic-research-paper-dynamic-single-box-heading-first
	strong {
	color: #22549e;
}
.conferenc-name-year {
	text-align: start;
	padding: 0px 15px;
	color: #22549e;
}
@media (max-width: 575px) {
	.academic-research-paper-dynamic-single-box-heading {
		display: grid;
		grid-template-columns: 100%;
		justify-content: space-between;
	}
	.academic-research-paper-dynamic-single-box-heading-first {
		margin-bottom: 5px;
	}
	.academic-research-paper-dynamic-single-box-content-container {
		display: grid;
		grid-template-columns: 100%;
		justify-content: space-between;
	}
	.conferenc-name-year {
		margin-top: 12px;
	}
}
