.bottomnavbar {
  background-color: #ffee00;
  /* height: 4rem; */
  display: flex;
  align-items: center;
}

.bottom-navbar-list {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-right: 10px;
  padding-inline-start: 5px;
}

.bottom-navbar-list li {
  list-style: none;
  font-weight: 600;
  cursor: pointer;

}

.bottom-navbar-list li a {
  list-style: none;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  text-decoration: none;
}

.bottom-navbar-list li:hover{
  color: #008080;
}
 
.bottom-navbar-list li a:hover {
  color: #008080;
}

.about {
  position: relative;
  padding: 0.5rem;
}

.about-list {
  position: absolute;
  top: 40px;
  left: -47px;
  z-index: 5;
  display: none;
  background-color: #008080;
  color: black;
  padding: 1rem;
  line-height: 2rem;
  width: 20rem;
  border-radius: 10px;
}

.about-list li {
  border-bottom: 1px solid #fff;
  text-align: start;
  color: #fff;
}

.about-list li a {
  font-weight: 600;
  text-align: start;
  color: #fff;
  text-decoration: none;
}

.last-list-btn {
  border-bottom: none !important;
}

.about-list li a:hover {
  color: #ffee00;
}

.about:hover .about-list {
  display: inline-block;
}

.top-nav {
  display: none;
}

.header-college-phone-number {
  color: #000 !important;
  text-decoration: none;
}

@media only screen and (max-width : 700px) {

  .bottomnavbar {
    display: none;
  }

  .bottomnavbar.active {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .bottom-navbar-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;

  }

  .bottom-navbar-list li {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .about-list {
    top: 45px;
    left: 1px;
  }

  .about:hover .about-list {
    display: inline-block;
    position: static;
  }
}

.collegelogo-container {
  padding: 10px 0px;
}

.collegelogo-row::after {
  content: "";
  display: table;
  clear: both;
}

.column1.column {
  width: 45%;
  float: left;
}

.column2.column {
  width: 40%;
  float: right;
}

.college-logo-img {
  width: 60%;
  display: flex;
  justify-content: start;
}

.college-logo.column1.column {

  padding-left: 25px;
}

.email-phone-container {
  margin-top: 5%;
  text-align: end;
  float: right;
  padding-right: 25px;
}

.email-phone-container p {
  margin: 0px;
  font-size: 16px;
  text-align: start;
}

@media(min-width: 768px) and (max-width: 991.98px) {
  .collegelogo-container {
    padding: 18px 18px;
  }

  .column1.column {
    width: 50%;
  }

  .column2.column {
    width: 47%;
  }

  .email-phone-container {
    margin-top: 8%
  }

  .email-phone-container p {
    font-size: 18px;
  }
}

@media(max-width:575px) {
  .collegelogo-container {
    padding: 5px 15px 5px 0px;
  }

  .college-logo.column1.column {
    padding-left: 10px;
  }

  .college-logo-img {
    width: 90%;
  }

  .column1.column {
    width: 80%;
  }

  .column2.column {
    width: 100%;
  }

  .email-phone-container {
    margin-top: 0;
    margin-left: 2%;
  }

  .email-phone-container p {
    font-size: 16px;
  }

  .college-email-phone.column2.column {
    display: none;
  }

  .top-nav {
    display: block;
  }

  .burger-icon {
    margin-top: 5%;
    font-size: 24px;
    float: right;
    padding-right: 1%;
  }

  .about .about-list {
    display: none !important;
    transition: .5s;
  }

  .about.submenu-active .about-list {
    display: block !important;
    width: 98%;
    top: 35px;
    transition: .5s;
    padding: 0px 0.5rem;
  }

  .bottomnavbar {
    width: 100%;
    display: flex;
    position: fixed;
    right: -100%;
    transition: .5s;
    overflow-y: scroll;
    z-index: 11;
    overflow: auto;
  }

  .bottomnavbar.active {
    width: 100%;
    height: 100%;
    right: 0;
    overflow: auto;
    top: 0;
  }

  .fa-solid.fa-xmark.burger-icon {
    font-size: 25px;
    float: right;
    padding-right: 1%;
    z-index: 15;
    position: fixed;
    top: 5px;
    transition: .3s;
    right: 14px;
  }

  .fa-solid.fa-bars.burger-icon {
    font-size: 24px;
    float: right;
    padding-right: 1%;
    margin-top: 5%;
    transition: .3s;
  }

  .bottom-navbar-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-top: 14%;
    padding-inline-start: 20px;
  }

  .bottom-navbar-list li {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem 0rem;
    font-weight: 500;
    border-bottom:1px solid rgb(253, 218, 13);
  }

  .bottom-navbar-list .about .drop-down-arrow-nav-bar {
    float: right;
    margin-right: 20px;
    height: 0.8rem;
    width: 0.7rem;
    margin-top: 5px;
  }

  .about-list li a {
    font-weight: 500;
    font-size: 14px;
    display: block;
  }

  .about-list li {
    border-bottom: 1px solid rgb(255, 255, 0, .4); /* or we can try 0,0,128*/
  }
}