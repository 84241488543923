.mous-page-container {
    margin-top: 5%;
    margin-bottom: 5%;
}
.mous-page-table {
    width: 100%;
}
.mous-page-table tr td {
    border: 1px solid #000;
    color: #22549e;
    font-weight: 700;
    text-align: start;
    padding: 10px 15px 10px 15px;
}

.mous-page-pdf {
    background-color: #22549e;
    color: #fff !important;
    width: 8%;
    text-align: center !important;
    font-size: 25px;
    cursor: pointer;
}
.mous-page-pdf a {
    color: #fff !important;
}
.mous-page-pdf-view {
    margin-bottom: 5px;
}
@media(max-width:575px) {
    .mous-page-table-container {
        overflow-x: auto;
    }
    .mous-page-table tr td {
        white-space: nowrap;
        padding: 5px 10px 5px 10px;
    }
    .mous-page-pdf {
        font-size: 17px;
        padding: 0px 20px !important;
    }
}