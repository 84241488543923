.naac-page-container {
    margin-top: 3%;
    margin-bottom: 5%;
    padding: 0;
}

.naac-page-message-container {
    text-align: start;
}

.naac-page-message-container p {
    margin: 0;
}

.naac-top-6-conatiner-row {
    margin-top: 3%;
    display: grid;
    grid-template-columns: 31% 31% 31%;
    justify-content: space-between;
}

.naac-top-6-conatiner-row .top-6-tags {
    margin-bottom: 5%;
    background-color: #22549e;
}

.naac-top-6-conatiner-row .top-6-tags a {
    text-decoration: none;
    color: #fff !important;
    padding: 15px 0px;
    width: 100%;
    display: block;
    font-weight: 500;
}

.nacc-hr-line {
    opacity: 1;
}

.naac-quality-heading {
    text-transform: uppercase;
    color: #22549e;

}

.naac-quality-heading h4 {
    font-weight: 700;
    margin: 0;
}

.naac-quality-row-container {
    margin-top: 3%;
    display: grid;
    grid-template-columns: 31% 31% 31%;
    justify-content: space-between;
}

.naac-quality-anchor-container {
    margin-bottom: 5%;
    background-color: #22549e;
    color: #fff !important;
}

.naac-quality-anchor-container a {
    text-decoration: none;
    color: #fff !important;
    padding: 15px 0px;
    width: 100%;
    display: block;
    font-weight: 500;
}

@media(max-width:575px) {
    .naac-page-container {
        margin-top: 5%;
        padding: 0 20px;
    }

    .naac-top-6-conatiner-row {
        margin-top: 5%;
    }

    .naac-top-6-conatiner-row .top-6-tags a {
        padding: 5px;
    }

    .naac-quality-anchor-container a {
        padding: 5px;
        font-size: 14px;
    }

    .naac-quality-anchor-container {
        margin-bottom: 10%;
    }

    .naac-top-6-conatiner-row {
        margin-top: 3%;
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-between;
    }

    .naac-top-6-conatiner-row .top-6-tags {
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
    .naac-quality-row-container {
        margin-top: 3%;
        display: grid;
        grid-template-columns:48% 48%;
        justify-content: space-between;
    } 
    .naac-quality-anchor-container {
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);   
    }
}