.code-of-ethics-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.code-of-ethics-content p {
    text-align: start;
}
.code-of-ethics-table-container {
    margin-top: 4%;
}
table.code-of-ethics-table {
    margin-left: auto;
    margin-right: auto;
}
.code-of-ethics-table tr td {
    border: 1px #000 solid;
    padding: 10px 30px 10px 30px;
    color: #22549E;
    font-weight: 600;
}
.code-of-ethics-table tr td:nth-child(2) {
    width: 80%;
    text-align: start;
}
.code-of-ethics-table tr td:nth-child(3) {
    width: 12%;
    background-color: #22549E;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}
.code-of-ethics-pdf a {
    color: #fff;
}
@media(max-width:575px) {
    .code-of-ethics-table-container {
        width: 100%;
        overflow-x: auto;
    }
    .code-of-ethics-table tr td{
        padding: 10px 15px;
        white-space: nowrap;
    }
}