.admission-panel-page-form-container {
    margin-top: 3%;
    background-color: #fff;
    padding: 5% 5% 5% 5%;

}

.admission-panel-page-form .admission-panel-page-form-label {
    display: block;
    color: #225493;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: start;
}

.admission-panel-page-form .admission-panel-page-form-full-name {
    width: 100%;
    padding: 8px 10px;
}

.admission-panel-page-form-row {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
    margin-top: 2%;
}

.admission-panel-page-form-label.ask-you-query {
    margin-top: 2%;
}

.ask-your-query-container {
    width: 100%;
}

.admission-panel-page-form-submit-btn-container {
    margin-top: 3%;
    text-align: start;
}

.admission-panel-page-form-submit-btn-container .admission-panel-page-form-submit-btn {
    background-color: #22549e;
    border: none;
    outline: none;
    color: #fff;
    padding: 10px 50px;
}
.admission-panel-page-form-submit-btn-container .admission-panel-page-form-submit-btn:hover{
    background-color: #008080;
}
@media (max-width:575px) {
    .admission-panel-page-form-container {
        margin-top: 20px;
        padding: 5% 4% 5% 4%;
    }

    .admission-panel-page-form .admission-panel-page-form-label {
        font-size: 1.1rem;
        margin-bottom: 0.3rem;
    }

    .admission-panel-page-form .admission-panel-page-form-full-name {
        padding: 5px 7px;
        
    }

    .admission-panel-page-form-row {
        margin-top: 3.5%;
        grid-template-columns: 100%;
        row-gap: 6.5%;
    }

    .admission-panel-page-form-label.ask-you-query {
        margin-top: 5.5%;
    }

    .ask-your-query-container {
        padding: 5px 3px;
        overflow: auto;
    }

    .admission-panel-page-form-submit-btn-container .admission-panel-page-form-submit-btn {
        background-color: #22549e;
        border: none;
        outline: none;
        color: #fff;
        padding: 5px 20px;
        font-size: 14px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }

}