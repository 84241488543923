.dynamic-results-container {
    margin-top: 3%;
    margin-bottom: 3%;
}
.dynamic-result-course-heading h1{
    color: #22549E;
}
.dynamic-result-table-container {
    margin-top: 2%;
}
table.dynamic-result-table {
    margin-left: auto;
    margin-right: auto;
}
.dynamic-result-table tr th {
    border: 1px #000 solid;
    color: #fff;
    background-color: #22549E;
    padding: 10px 50px;
}
.dynamic-result-table tr td {
    border: 1px #000 solid;
    padding: 10px 20px;
    text-align: center;
    font-weight: 700;
}
.dynamic-result-table tr td.dynamic-result-pdf {
    background-color: #22549E;
    outline: none;
    text-align: center;
    cursor: pointer;
}
.dynamic-result-table tr td.dynamic-result-pdf .dynamic-result-pdf-eye{
    color: #fff;
    font-size: 20px;
    text-align: center;
}
@media (max-width:575px) {
    .dynamic-result-table-container {
        overflow-y: auto;
    }
    .dynamic-result-table tr th {
        padding: 5px 20px;
        white-space: nowrap;
    }
    .dynamic-result-table tr td {
        padding: 5px 20px;
        white-space: nowrap;
    }
}