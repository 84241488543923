.admission-form-padding {
    padding-bottom:5% ;
}
.admission-enquiry-city-state-pin {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
    row-gap: 15px;
}
.admission-mobile-amount-row {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
}
.admission-form-payment-container {
    margin-top: 2%;
}
.admission-form-payment-container {
    margin-top: 2%;
}
.admission-form-payment-container .admission-form-payment-btn {
    border: none;
    outline: none;
    width: 100%;
    background-color: #22549e;
    color: #fff;
    padding: 10px;
    border-radius: 9px;
}
.admission-enquiry-form-input:focus {
    outline: none !important;
    border:1px solid #c2adad;
}
@media(max-width:575px) {
    .admission-enquiry-city-state-pin {
        row-gap: 8px;
    }
    .admission-mobile-amount-row {
        display: grid;
        grid-template-columns: 100%;

    }
}