.best-practices-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.best-practices-buttons {
    display: grid;
    grid-template-columns: 50% 50%;
}
.best-practices-button {
    margin-bottom: 6%;
}
.best-practices-button a button {
    background-color: #22549E;
    color: #fff;
    font-weight: 600;
    box-shadow: 10px -10px 0px rgb(255 238 0);
    padding: 15px 20px;
    width: 80%;
    font-size: 20px;
    border: none;
}
.best-practices-button a button:hover{
    background-color: #008080;
}
@media(max-width:575px) {
    .best-practices-container {
        margin-top: 5%;
    }
    .best-practices-buttons {
        grid-template-columns: 100%;
        justify-content: space-between;
    }
    .best-practices-button a button {
        font-size: 16px;
        padding: 10px 15px;
    }
}