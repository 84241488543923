.best-practice-details-container {
    margin-top: 5%;
    margin-bottom: 5%;
}
.best-practice-details-row {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
}
.best-practice-details-column a {
    text-decoration: none;
    font-size: 20px;
    box-shadow: 10px -10px 0px rgb(255 238 0);
    width: 80%;
    color: #fff;
    background-color: #22549E;
    font-weight: 600;
    display: block;
    padding: 15px 15px;
}
.best-practice-details-column a:hover {
    background-color: #008080;
}
@media(max-width:575px){
    .best-practice-details-row {
        grid-template-columns: 100%;
        justify-content: center;
        align-items: center;
        text-align:center;
    }
    .best-practice-details-column  a {
        margin:0% 10% 8% 10%;
        font-size: 16px;
        padding: 5px 10px;
    }
}