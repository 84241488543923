.dynamic-home-page-image-modal-body {
    padding: 0;
}
.dynamic-home-page-image-container img{
    width: 100%;
}
.dynamic-home-page-image-modal-body {
    background-color: none !important;
}
.dynamic-home-page-image-modal-container .modal-dialog.modal-lg.modal-dialog-centered .modal-content {
    background-color: transparent !important;
    border: none;
}
.dynamic-home-page-image-modal-close-btn-container {
    background: transparent;
    text-align: right;
    padding: 5px 5px;
}