.faculty-development-program-container {
    margin-top: 3%;
    margin-bottom: 5%;
}
.faculty-development-program-test {
    padding: 0% 6% 0% 2%;
    margin-bottom: 3%;
}
.mandatory-committees-title {
    display: inline-block;
    border: 1px solid #000;
    padding: 12px;
    text-align: start;
    width: 75%;
    font-size: 18px;
    font-weight: 600;
    color: #22549e;
    padding-left: 20px;
}
.mandatory-committees-pdf {
    display: inline-block;
    border: 1px solid #000;
    padding: 13.5px 44px 11px 40px;
    background-color: #22549e;
    cursor: pointer;
}
.mandatory-committees-pdf-view {
    margin-bottom: 5px;
}