.massive-online-courses-container {
	margin-top: 3%;
	margin-bottom: 5%;
}

.massive-online-courses-table tr td {
	border: 1px solid #000;
	text-align: start;
	padding-left: 1%;
	color: #22549e;
	font-weight: 700;
}

.massive-online-courses-table tr td:nth-child(2) {
	padding-right: 1%;
}

.massive-online-courses-table tr td:nth-child(3) {
	border: 1px solid #000;
	text-align: center;
	padding-left: 1%;
	color: #fff;
	cursor: pointer;
	width: 13%;
	background-color: #22549e;
	padding-top: 15px;
	font-weight: 300;
}

.massive-online-courses-view-link-container .massive-online-courses-pdf {
	font-size: 19px;
}

.massive-online-courses-pdf p {
	font-size: 16px;
}
.mooc-link {
	color: #fff;
	text-decoration: none;
}
@media (max-width: 575px) {
	.massive-online-courses-container {
		margin-top: 5%;
	}

	.massive-online-courses-table-container {
		overflow-x: auto;
	}

	.massive-online-courses-table tr td {
		white-space: nowrap;
		border: 1px solid #000;
		text-align: start;
		padding-left: 12px;
		color: #22549e;
		font-weight: 700;
		padding-right: 12px;
	}

	.massive-online-courses-table tr td:nth-child(3) {
		border: 1px solid #000;
		text-align: center;
		padding-left: 10px;
		color: #fff;
		cursor: pointer;
		background-color: #22549e;
		padding-top: 10px;
		font-weight: 300;
	}
	.massive-online-courses-view-link-container .massive-online-courses-pdf {
		font-size: 17px;
	}
	.massive-online-courses-pdf p {
		font-size: 14px;
		margin-bottom: 0.7rem;
	}
}
