.director-message-container {
    padding: 4% 5%;
}
.director-details {
    display: flex;
    justify-content: center;
}
.director-message-content {
    text-align: start;
    padding: 1%;
}
.director-image img {
    border-radius: 100%;
    max-width: 300px;
    max-height: 300px;
    min-width: 250px;
    min-height: 250px;
}
.director-image h2 {
    padding: 6%;
    color: #22549E;
}
.director-message-content strong {
    font-size: 22px;
    font-weight: 400;
}
.director-message-content .with-best {
    margin-bottom: 0;
}
@media(max-width:575px) {
    .director-image h2 {
        font-size:1.3rem;
    }
    .director-message-content strong {
        font-size: 18px;
    }
    .director-message-content p {
        font-size: 15px;
    }
    .director-message-content ul {
        padding-left: 1rem;
    }
}