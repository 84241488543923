.conference-container {
    margin-top: 3%;
    margin-bottom: 5%;
}

.single-conference-comtainer {
    padding: 10px 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-bottom: 5%;
}

.conference-heading {
    margin-top: 2%;
}

.conference-heading h4 {
    color: #22549e;
    margin-bottom: 0px;
}

.conference-message {
    margin-top: 2%;
    padding: 10px 60px;
}

.conference-images-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: space-between;
}

.conference-images-container img {
    height: 220px;
    width: 350px;
    border-radius: 20px;
    margin-left: 12%;
    object-fit: fill;
    display: flex;
    align-items: center;
    justify-content: center;
}

.conference-more-image {
    text-align: end;
    margin-top: 2%;
}

.conference-more-image .conference-more-image-button {
    background-color: #225493;
    color: #fff;
    border-radius: 10px;
    padding: 7px 16px;
    font-size: 14px;
    border: none;
    outline: none;
}
.conference-more-image .conference-more-image-button:hover {
    background-color: #008080;
}
.confrence-more-content-paragraph {
    display: inline;
}

.confrence-more-content-button {
    margin-left: 1px;
    background: none;
    border: none;
    outline: none;
    text-decoration: underline;
    color: #22549e;
}

@media(max-width:575px) {
    .conference-container {
        margin-top: 5%;
    }

    .single-conference-comtainer {
        padding: 10px 5px;
    }

    .conference-message {
        padding: 10px 8px;
    }

    .conference-images-container {
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-around;
        gap:20px
    }
    .conference-images-container img {
        height: 220px;
        width: 310px;
        border-radius: 20px;
        margin-left: auto;
        object-fit: fill;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
    }
    .conference-more-image {
        margin-top: 3%;
    }
    .conference-more-image .conference-more-image-button {
        padding: 5px 10px;
    }
}