.pages-quote-container {
    margin-top: 4%;
    border-top: 2px solid #008080;
    border-bottom: 2px solid #008080;
    padding-bottom: 2%;
}
.pages-quote-container .pages-quote{
    padding: 20px 20px 20px 20px;
}
.opening-quotes-symbol {
    float: left;
    font-size: 30px;
    color: #008080;
}
.closing-quotes-symbol {
    float: right;
    font-size: 30px;
    color: #008080;
}
.pages-quote-container .pages-quote .pages-quotes-content {
    display: block;
    padding: 3%;
    margin-left: 5%;
}
.pages-quote-container .pages-quote .pages-quotes-content h5{
    font-size: 2rem;
    color: #008080;
    text-align: start;
}
@media(max-width:575px){
    .pages-quote-container .pages-quote {
        padding: 15px 19px 20px 10px;
    }
    .opening-quotes-symbol {
        font-size: 16px;
    }
    .pages-quote-container .pages-quote .pages-quotes-content {
        padding: 2% 2% 2% 3%;
    }
    .pages-quote-container .pages-quote .pages-quotes-content h5 {
        font-size: 1rem;
    }
    .closing-quotes-symbol {
        font-size: 16px;
    }
}
