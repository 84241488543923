.chairman-director-container {
    padding: 20px 25px;
    margin-top: 3%;
}

.chairman-director-row {
    display: flex;
    flex-direction: row;
    column-gap: 8%;
    justify-content: center;
}

.chairman-image-container img {
    width: 430px;
    border-radius: 15px;
    height: 312px;
    object-fit: fill;
}

.director-image-container img {
    width: 430px;
    border-radius: 15px;
    height: 312px;
    object-fit: fill;
}

.chairma-content {
    padding: 0px 20px;
    margin-top: 2%;
}

.chairma-content h1 {
    margin: 0px;
    font-size: 1.7rem;
}

.chairma-content p {
    margin: 0px;
    margin-top: 1.5%;
}

.chairma-content button {
    margin-top: 2%;
    border: none;
    outline: none;
    background-color: #008080;
    color: #fff;
    padding: 10px;
    transition: .5s;
}
.chairma-content button a{
    text-decoration: none;
    color: #fff;
}

.chairma-content button:hover {
    background-color: #FFEE00;
}
.chairma-content .read-message-link:hover .read-message {
    color: #000 !important;
    text-decoration: none;
}

@media(max-width:575px) {
    .chairman-director-row {
        flex-direction: column;
        column-gap: 0;
        row-gap: 20px;
    }
    .chairma-content {
        margin-top: 3%;
    }

    .chairma-content h1 {
        font-size: 1.3rem;
    }

    .chairma-content p {
        margin: 0px;
        margin-top: 1%;
        font-size: 18px;
    }
    .chairma-content button {
        padding: 5px 10px;
        font-size: 14px;
    }
    .chairman-image-container img {
        width: 100%;
        border-radius: 15px;
        height: 295px;
        object-fit: fill;
    }
    
    .director-image-container img {
        width: 100%;
        border-radius: 15px;
        height: 295px;
        object-fit: fill;
    }
}