.college-committees-container {
    margin-top: 3%;
    margin-bottom: 3%;
}
.college-committees-mandatory-committees-heading {
    background-color: #22549e;
    text-align: center;
    padding: 15px;
    margin-bottom: 2%;
}
.college-committees-mandatory-committees-heading .college-committees-mandatory-committees-title {
    margin: 0;
    font-weight: 600;
    color: #fff;
}
.college-committees-mandatory-committees-row {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
}
.college-committees-mandatory-committees-content {
    margin-bottom: 4%;
    display: grid;
    grid-template-columns: 80% 20%;
}
.college-committees-mandatory-committees-list {
    display: inline-block;
    border: 1px solid #000;
    color: #22549e;
    font-weight: 700;
    padding: 15px;
    width: 100%;
    text-align: start;
    float: left;
}
.college-committees-mandatory-committees-pdf {
    border: 1px solid #000;
    outline: none;
    background-color: #22549e;
    color: #fff;
    padding: 12px;
    float: left;
    width: 100%;
    font-size: 20px;
}




.college-committees-student-committees-heading {
    background-color: #22549e;
    text-align: center;
    padding: 15px;
    margin-top: 3%;
    margin-bottom: 2%;
}
.college-committees-student-committees-heading .college-committees-student-committees-title {
    margin: 0;
    font-weight: 600;
    color: #fff;
}
.college-committees-student-committees-row {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
}
.college-committees-student-committees-content {
    margin-bottom: 4%;
    display: grid;
    grid-template-columns: 80% 20%;
}
.college-committees-student-committees-list {
    display: inline-block;
    border: 1px solid #000;
    color: #22549e;
    font-weight: 700;
    padding: 15px;
    width: 100%;
    text-align: start;
    float: left;
}
.college-committees-student-committees-pdf {
    border: 1px solid #000;
    outline: none;
    background-color: #22549e;
    color: #fff;
    padding: 12px;
    float: left;
    width: 100%;
    font-size: 20px;
}

@media(max-width:575px) {
    .college-committees-mandatory-committees-heading {
        padding: 5px;
        margin-bottom: 3%;
    }
    .college-committees-mandatory-committees-row {
        display: grid;
        grid-template-columns: 100%;
    }
    .college-committees-mandatory-committees-list {
        padding: 6.5px;
    }
    .college-committees-mandatory-committees-pdf {
        padding: 5px;
        font-size: 18px;
    }
    .college-committees-mandatory-committees-pdf-view {
        margin-bottom: 5px;
    }
    .college-committees-student-committees-heading {
        padding: 5px;
    }
    .college-committees-student-committees-row {
        display: grid;
        grid-template-columns: 100%;
    }
    .college-committees-student-committees-list {
        padding: 6.5px;
    }
    .college-committees-student-committees-pdf {
        padding: 5px;
        font-size: 18px;
    }
    .college-committees-student-committees-pdf-view {
        margin-bottom: 5px;
    }
}